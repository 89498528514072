import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import { withRouter } from 'react-router-dom';
import { Table, Popconfirm, Row, Col, DatePicker, Input, Tabs } from 'antd';
import loader from '../../assets/imgs/loader.gif';
import { saveAs } from 'file-saver';
import { fileAttachment, generateReport } from '../../services/auth';
import Moment from 'react-moment';
import * as moment from 'moment';
import { addActivityLogs, isEmpty } from '../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../utils/native';
import * as rigActions from '../../redux/actions/rigActions';

const { Column } = Table;
const { TabPane } = Tabs;
class IndividualTruckLoads extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDeletedPermitted: checkDeletePermissions(props, 'individualLoads'),
			isLoading: false,
			reports: [],
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			rigs: [],
			searchInput: '',
			from: null,
			to: null,
			users: props.auth.usersList,
			rig: '',
			selectedTab: 1,
			permitLoads: [],
			nonPermitLoads: []
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'individualLoads'), isDeletedPermitted: checkDeletePermissions(this.props, 'individualLoads') });
		}
		const { usersList } = this.props.auth;
		if (prevProps.auth.usersList.length !== usersList.length) {
			this.setState({ users: usersList });
		}
		const { rigsList } = this.props.rig;
		if (prevProps.rig.rigsList.length !== rigsList.length) {
			this.getRigs();
		}
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getRigs();
	}

	getRigs() {
		const { rigsList } = this.props.rig;
		this.setState({ rigs: rigsList }, () => {
			if (rigsList.length > 0) {
				this.getReports();
			}
		});
	}

	getReports() {
		const { individualPermitReportList } = this.props.rig;
		if (individualPermitReportList.length > 0) {
			this.fetchReportsData(individualPermitReportList);
			return;
		}
		const reportsRef = firebase.firestore().collection('rigReports').where('reportType', '==', 'permit_report').where('individual', '==', true).where('archive', '==', false).orderBy('generatedOn', 'desc');
		reportsRef.get().then((querySnapshot) => {
			const reportList = querySnapshot.docs.map((doc) => {return {...doc.data(), '$key': doc.id};});
			this.props.dispatchIndividualPermitReportList(reportList);
			this.fetchReportsData(reportList);
		});
	}

	fetchReportsData(reports) {
		const { rigs } = this.state;
		const permitLoads = [];
		const nonPermitLoads = [];
		reports.forEach((data) => {
			this.getUserDetails(data);
			const userIndex = rigs.map(admin => admin.$key).indexOf(data.rigId);
			if (userIndex !== -1 && rigs[userIndex].archive !== true) {
				data.rigName = rigs[userIndex].name;
				data.rigNumber = rigs[userIndex].number;
				data.rigJobNumber = rigs[userIndex].jobNumber;
				if (data.permitLoad === true) {
					permitLoads.push(data);
				} else {
					nonPermitLoads.push(data);
				}
			}
		});
		let totalLoads = permitLoads.concat(nonPermitLoads);
		totalLoads = totalLoads.sort((a, b) => (a.date < b.date) ? 1 : -1);
		this.setState({ reports: totalLoads, permitLoads, nonPermitLoads, isLoading: false });
	}

	getUserDetails(data) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(data.uid);
		if (userIndex !== -1) {
			data.updatedByUser = users[userIndex].firstName + ' ' + users[userIndex].lastName;
		}
	}

	getRigById(rigId) {
		return new Promise((resolve) => {
			const { rigsList } = this.props.rig;
			const temp = rigsList.find((item) => item.$key === rigId);
			this.setState({ rig: temp });
			resolve(temp);
		});
	}

	viewReport(report) {
		this.props.history.push(`/individual_truck_loads/${report.$key}`);
	}

	handleDownload(selectedReport) {
		this.setState({ isLoading: true });
		fileAttachment(selectedReport)
			.then((res) => {
				saveAs(res.data.file, selectedReport.title);
				this.setState({ isLoading: false });
			});
	}

	getUserById(userId) {
		return new Promise((resolve) => {
			const { usersList } = this.props.auth;
			const temp = usersList.find((item) => item.uid === userId);
			resolve(temp);
		});
	}

	getLoadsByRigId(collectionName, rigId, selectedDate, permitLoad, noteId) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query;
			if (!isEmpty(noteId)) {
				query = db.collection(collectionName).where('rigId', '==', rigId).where('$key', '==', noteId);
			} else {
				query = db.collection(collectionName).where('rigId', '==', rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			}
			query.get().then((querySnapshot) => {
				const loads = [];
				querySnapshot.forEach(note => {
					const item = note.data();
					item.$key = note.id;
					if (!item.archive) {
						if (!isEmpty(permitLoad) && item.permitLoad === permitLoad) {
							loads.push(item);
						}
						if (isEmpty(permitLoad)) {
							loads.push(item);
						}
					}
				});
				resolve({ list: loads });
			});
		});
	}

	getArrayByDates = (list) => {
		return new Promise(async(resolve) => {
			const indexesArray = [], indexes = [];
			for(const i in list) {
				if (!list[i].archive) {
					const index = indexes.indexOf(list[i].date);
					if (index === -1) {
						indexes.push(list[i].date);
						const newIndex = indexes.indexOf(list[i].date);
						indexesArray[newIndex] = {
							title: list[i].createdAt,
							items: []
						};
						indexesArray[newIndex].items.push(list[i]);
					} else {
						if (!indexesArray[index]) {
							indexesArray[index] = {
								title: list[i].createdAt,
								items: []
							};
						}
						indexesArray[index].items.push(list[i]);
					}
				}
				if (parseInt(i) === (list.length - 1)) {
					resolve(indexesArray);
				}
			}
		});
	};

	async fetchReportDetails(selectedReport) {
		const rigDetails = await this.getRigById(selectedReport.rigId);
		let userDetails;
		const { currentUser } = this.props.auth;
		if (currentUser.$key !== selectedReport.uid) {
			userDetails = await this.getUserById(selectedReport.uid);
		} else {
			userDetails = currentUser;
		}

		const data = {
			generatedOn: selectedReport.generatedOn,
			selectedDate: selectedReport.date,
			endDate: selectedReport.endDate,
			rig: {
				...rigDetails,
				releaseDate: typeof (rigDetails.releaseDate) === 'string' ? rigDetails.releaseDate : ''
			},
		};
		const queryDown = await this.getLoadsByRigId('Rig-Down', selectedReport.rigId, selectedReport.date, selectedReport.endDate, selectedReport.permitLoad, selectedReport.noteId);
		const queryUp = await this.getLoadsByRigId('Rig-Up', selectedReport.rigId, selectedReport.date, selectedReport.endDate, selectedReport.permitLoad, selectedReport.noteId);
		const rigDownNotes = queryDown.list;
		const rigUpNotes = queryUp.list;

		if (rigDownNotes.length > 0) {
			data.rigDownNotes = await this.getArrayByDates(rigDownNotes);
		}
		if (rigUpNotes.length > 0) {
			data.rigUpNotes = await this.getArrayByDates(rigUpNotes);
		}
		this.generateFileFromDetails(data, userDetails, selectedReport);
	}

	generateFileFromDetails(data, userDetails, selectedReport) {
		this.setState({ isLoading: true });
		const folderName = userDetails.uid + '/dailyreports/';
		generateReport(data, selectedReport.reportType, folderName)
			.then(async (res) => {
				selectedReport.url = res.data.url;
				const db = firebase.firestore();
				await db.doc(`rigReports/${selectedReport.$key}`).update(selectedReport);
				fileAttachment({url: res.data.url})
					.then((res) => {
						saveAs(res.data.file, selectedReport.title);
						this.setState({ isLoading: false });
					}).catch(() => {
					});
			}).catch(() => {
			});
	}

	async handleDelete(report) {
		const db = firebase.firestore();
		const { individualPermitReportList } = this.props.rig;
		const temp = individualPermitReportList.find((item) => item.$key === report.$key);
		if (typeof temp === 'object' && Object.keys(temp).length !== 0) {
			const updatedList = individualPermitReportList.filter((item) => item.$key !== report.$key);
			this.props.dispatchIndividualPermitReportList(updatedList);
			await db.collection('rigReports').doc(report.$key).update({...report, archive: true });
			await this.getRigById(report.rigId);
			this.updateLogs();
			this.getReports();
		}
	}

	updateLogs = async() => {
		const { rig } = this.state;
		await addActivityLogs(rig, 'delete', 'Permit Report', rig.$key)
			.then(() => {
				alert('Report is deleted successfully');
			});
	}

	reportSearch(value) {
		this.setState({ searchInput: value.toLowerCase() });
	}

	fromDate(date) {
		this.setState({ from: date !== null ? moment(date).format('YYYY-MM-DD') : null });
	}

	toDate(date) {
		this.setState({ to: date !== null ? moment(date).format('YYYY-MM-DD') : null });
	}

	rigsHeader = () => {
		const { Search } = Input;
		return (
			<Row className='rowContent search-rig-header'>
				<Col span={12} className='searchInput spacing-top'>
					<Search
						placeholder='Enter Rig Name, Rig Number, Rig Job Number, Type, Truck, Driver Name'
						className='rigSearch custom-search'
						allowClear={true}
						onChange={(data) => this.reportSearch(data.target.value)}
					/>
				</Col>
				<Col
					className='custom-date-picker spacing-bottom'
					span={6}
					id='DropdownFixPostion'
				>
					<span className='small-title'>From:</span>
					<DatePicker
						onChange={(value) => this.fromDate(value)}
						className='date_picker'
					/>
				</Col>
				<Col
					className='custom-date-picker spacing-bottom'
					span={6}
					id='DropdownFixPostion1'
				>
					<span className='small-title'>To:</span>
					<DatePicker
						onChange={(value) => this.toDate(value)}
						className='date_picker'
					/>
				</Col>
			</Row>
		);
	}

	filterData = (list) => {
		const { searchInput, from, to } = this.state;
		return list.filter((item) => (item.rigName.toLowerCase().match(searchInput) || item.rigNumber.toLowerCase().match(searchInput) || item.rigJobNumber.toLowerCase().match(searchInput)
		|| item.reportType.toLowerCase().match(searchInput) || (item.truck && item.truck.toLowerCase().match(searchInput))
		|| (item.driverName && item.driverName.toLowerCase().match(searchInput))) && (from === null || item.date >= from) && (to === null || item.date <= to));
	};

	rigsLists = (lists) => {
		const { reports, isAdmin, isDeletedPermitted, isLoading } = this.state;
		if (isLoading === true) {
			return (
				<div className='loader'>
					<img src={loader} alt='loader' />
				</div>
			);
		}

		if(reports.length > 0){
			return <Table dataSource={this.filterData(lists)} rowKey={(record, index) => record.uid + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
				<Column title='Rig Name' dataIndex='rigName' key='rigName' />
				<Column title='Rig Number' dataIndex='rigNumber' key='rigNumber' />
				<Column title='Rig Job Number' dataIndex='rigJobNumber' key='rigJobNumber' />
				<Column title='Date' dataIndex='date' render={(_, record) =>
					record.endDate ?
						<>
							<Moment format='MMM DD, YYYY'>{record.date}</Moment> - <Moment format='MMM DD, YYYY'>{record.endDate}</Moment>
						</>
						:
						<Moment format='MMM DD, YYYY'>{record.date}</Moment>}
				/>
				<Column title='Type' dataIndex='reportType' render={(_, record) => this.getReportType(record)} />
				<Column title='Truck' dataIndex='truck' key='truck' />
				<Column title='Driver Name' dataIndex='driverName' key='driverName' />
				<Column title='Created By' dataIndex='updatedByUser' key='updatedByUser' />
				<Column title='View' dataIndex='View' render={(_, record) =>
					this.state.reports.length >= 1 ? (
						<p className='table-action-link box-link' onClick={() => this.viewReport(record)}>View</p>
					) : null}
				/>
				{isAdmin === true &&
					<Column title='Download' dataIndex='download'
						render={(_, record) =>
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to download this report?"
								onConfirm={() => record.url ? this.handleDownload(record) : this.fetchReportDetails(record)}>
								<a href='true' className='warning-box-link'>Download</a>
							</Popconfirm>
						}
					/>
				}
				{isAdmin === true && isDeletedPermitted &&
					<Column title='Delete' className='delete' dataIndex='Delete' render={(_, record) =>
						this.state.reports.length >= 1 ? (
							<Popconfirm overlayClassName='custom-popconfirm' title='Are you sure to delete this rig ?' onConfirm={() => this.handleDelete(record)}>
								<a href='true'>Delete</a>
							</Popconfirm>
						) : null}
					/>
				}
			</Table>;
		} else {
			return <div className='card-body'>
				<h3 className='card-title text-center'>No reports are found</h3>
			</div>;
		}
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key), searchInput: '', searchedUsers: [] });
	};

	getReportType(report) {
		return (report.permitLoad === true ? 'Permit Loads' : 'Non-Permit Loads');
	}

	render() {
		const { selectedTab, reports,permitLoads, nonPermitLoads} = this.state;

		return (
			<div>
				<div className='card-tab-body'>
					<div className="custom-tabs no-mt">
						<Tabs type="card" defaultActiveKey="1" onChange={this.onChange}>
							<TabPane tab='Non Permit Loads' key="1">
								{this.rigsHeader()}
								{selectedTab === 1 && this.rigsLists(nonPermitLoads)}
							</TabPane>
							<TabPane tab='Permit Loads' key="2">
								{this.rigsHeader()}
								{selectedTab === 2 && this.rigsLists(permitLoads)}
							</TabPane>
							<TabPane tab='All' key="3">
								{this.rigsHeader()}
								{selectedTab === 3 && this.rigsLists(reports)}
							</TabPane>
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

const mapDispatchToProps = {
	dispatchIndividualPermitReportList: (data) => rigActions.individualPermitReportList(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndividualTruckLoads));
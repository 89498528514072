import React, { Component } from 'react';
import { Col, DatePicker, Modal, Row } from 'antd';
import { TiDocumentText } from 'react-icons/ti';
import { IoIosAddCircleOutline, IoLogoYoutube } from 'react-icons/io';
import { BsExclamationCircle, BsLink45Deg } from 'react-icons/bs';
import { RiVideoLine } from 'react-icons/ri';
import { MdPictureAsPdf } from 'react-icons/md';
import { CgFormatText } from 'react-icons/cg';
import { SiHtmlacademy, SiQuicktime } from 'react-icons/si';
import { AiOutlineBarChart } from 'react-icons/ai';
import { VscLibrary } from 'react-icons/vsc';
import { FaWpforms } from 'react-icons/fa';
import { RiFileEditFill } from 'react-icons/ri';
import { CgTemplate } from 'react-icons/cg';
import './safety-form.css';
import { isEmpty } from '../../../utils/activity-logs';
import DragDropComponent from './drag-drop-component';

class AssetScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newTemplateModal: false,
			selectedObject: ''
		};
	}

	handleSelect = () => {
		const { selectedObject } = this.props;
		if (selectedObject !== '' && selectedObject !== undefined) {
			this.createTemplateModal();
		} else {
			alert('Please select any one object!');
		}
	}

	handleModalClose = (key) => {
		const { updateModalState } = this.props;
		updateModalState(key, false);
	}

	createTemplateModal = (value) => {
		this.setState({ selectedObject: value });
		this.handleModalClose('objectModal');
		setTimeout(() => {
			this.setState({ newTemplateModal: true });
		}, 1000);
	}

	openObjects = () => {
		return (
			<Row className="object-box-part">

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<RiVideoLine className='iconSize' />
						<p>Video</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<MdPictureAsPdf className='iconSize' />
						<p>PDF</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<CgFormatText className='iconSize' />
						<p>Text</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<SiHtmlacademy className='iconSize' />
						<p>HTML</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<IoLogoYoutube className='iconSize' />
						<p>Youtube</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<SiQuicktime className='iconSize' />
						<p>Quiz</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<AiOutlineBarChart className='iconSize' />
						<p>Survey</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<BsLink45Deg className='iconSize' />
						<p>Link</p>
					</div>
				</Col>

				<Col className="object-boxes">
					<div className="inner-area select-disabled">
						<VscLibrary className='iconSize' />
						<p>Library</p>
					</div>
				</Col>

				<Col className="object-boxes" onClick={() => this.createTemplateModal('form')}>
					<div className="inner-area">
						<FaWpforms className='iconSize' />
						<p>Forms</p>
					</div>
				</Col>

			</Row>
		);
	}

	getFormSelection = () => {
		return (
			<Row className="layout-box-part">
				<Col className="layout-box" onClick={() => this.createFormModal('scratch')}>
					<div className="inner-area">
						<RiFileEditFill className='iconSizeNew' />
						<p>Start from scratch</p>
					</div>
				</Col>
				<Col className="layout-box" onClick={() => this.createFormModal('template')}>
					<div className="inner-area">
						<CgTemplate className='iconSizeNew' />
						<p>Use a template</p>
					</div>
				</Col>
			</Row>
		);
	}

	handleSubmit = () => {
		const { selectedTemplate } = this.props;
		if (isEmpty(selectedTemplate)) {
			alert('Please select any one template!');
		}
	}

	createFormModal = (value) => {
		this.setState({ newTemplateModal: false });
		this.props.getSelectedObject('selectedTemplate', value);
		this.props.getSelectedObject('selectedObject', this.state.selectedObject);
		this.props.getSelectedObject('formAction', 'add');
	}

	handleTemplateClose = () => {
		this.setState({ newTemplateModal: false });
		this.handleModalClose('objectModal');
	}

	updateObjectDetails = (object) => {
		const { getFormContentDetails } = this.props;
		getFormContentDetails(object);
	}

	render() {
		const { newTemplateModal } = this.state;
		const { objectModal, updateModalState, title, objectList, updateFormOrderList, deleteObjectDetails, isWritePermitted, isDeletePermitted } = this.props;
		return (
			<>
				<div className="card-body">
					<div className="untitled-title">
						<TiDocumentText />
						{isEmpty(title) ?
							<h4 className="card-title no-mb">Untitled Section</h4>
							:
							<h4 className="card-title no-mb">{title}</h4>
						}
					</div>
					<Row>
						<Col span={6}>
							{(objectList.length > 0) &&
								<DragDropComponent
									items={objectList}
									formType="formList"
									getItemsOrder={(list) => updateFormOrderList(list)}
									updateObjectDetails={(object) => this.updateObjectDetails(object)}
									deleteObjectDetails={(object) => deleteObjectDetails(object)}
									isWritePermitted={isWritePermitted}
									isDeletePermitted={isDeletePermitted}
								/>
							}
							{isWritePermitted &&
								<button className="site-btn icon" onClick={() => updateModalState('objectModal', true)}>
									<IoIosAddCircleOutline className="IoIosAddCircleOutline-custom" />
									<span>Add Object</span>
								</button>
							}
						</Col>
						<Col span={18}>
							<div className="unpublish-box">
								<div className="content">
									<h6><BsExclamationCircle /> Unpublished</h6>
									<p>Unpublished asset will not appear in the mobile app</p>
								</div>
								<button className="site-btn">Publish</button>
							</div>
							<Row>
								<Col span={8}>
									<h6 className="primaryColor">Mobile App Engagement</h6>
								</Col>
								<Col span={8} className="custom-date-picker">
									<span>From:</span>
									<DatePicker onChange={(data) => this.onChange(data)} className='date_picker' />
								</Col>
								<Col span={8} className="custom-date-picker">
									<span>To:</span>
									<DatePicker onChange={(data) => this.onChangeDate(data)} className='date_picker' />
								</Col>
							</Row>
						</Col>
					</Row>
				</div>

				<Modal
					centered
					className="modal-footer-hide send-mail-modal"
					title='Create object'
					visible={objectModal}
					onOk={this.handleSelect}
					onCancel={() => this.handleModalClose('objectModal')}
					maskClosable={false}>
					{this.openObjects()}
				</Modal>

				<Modal
					centered
					className="modal-footer-hide send-mail-modal"
					title='Add new'
					visible={newTemplateModal}
					onOk={this.handleSubmit}
					onCancel={() => this.handleTemplateClose()}
					maskClosable={false}>
					{this.getFormSelection()}
				</Modal>
			</>
		);
	}
}
export default AssetScreen;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import './rig.css';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import loader from '../../assets/imgs/loader.gif';
import { Checkbox, Col, DatePicker, Row, Select, Table } from 'antd';
import { FaCheck } from 'react-icons/fa';
import rec from '../../assets/imgs/rec.png';
import Search from 'antd/lib/input/Search';
import { isEmpty } from '../../utils/activity-logs';
import { checkWritePermissions } from '../../utils/native';

class Rig extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(this.props, 'reports'),
			id: this.props.match.params.id,
			rig: '',
			down_notes: [],
			notes: [],
			columns: [
				{
					title: 'Date',
					dataIndex: 'date',
					key: 'date',
					render: (time) => <>
						<Moment format="MMM DD, YYYY">{time}</Moment>
						<span>
							{time.split('T')[1]}
						</span>
					</>,
				},
				{
					title: 'Rig Name',
					dataIndex: 'rigName',
					key: 'rigName',
					render: () => this.state.rig && this.state.rig.name,
				},
				{
					title: 'Rig Number',
					dataIndex: 'rigNumber',
					key: 'rigNumber',
					render: () => this.state.rig && this.state.rig.number,
				},
				{
					title: 'Created By',
					dataIndex: 'createdBy',
					key: 'createdBy',
					render: (_, record) => !isEmpty(record.createdBy) ? this.getUserDetails(record.createdBy) : ''
				},
				{
					title: 'Updated By',
					dataIndex: 'updatedBy',
					key: 'updatedBy',
					render: (_, record) => !isEmpty(record.updatedBy) ? this.getUserDetails(record.updatedBy) : ''
				},
				{
					title: 'Rig Part',
					dataIndex: 'part',
					key: 'part',
					render: (part) => part && part.map((parts) => parts.name).join(),
				},
				{
					title: 'Width',
					dataIndex: 'width',
					key: 'width',
					render: (_, record) => this.renderCustomCell(record.dimensions.width),
				},
				{
					title: 'Height',
					dataIndex: 'height',
					key: 'height',
					render: (_, record) => this.renderCustomCell(record.dimensions.height),
				},
				{
					title: 'Length',
					dataIndex: 'length',
					key: 'length',
					render: (_, record) => this.renderCustomCell(record.dimensions.length),
				},
				{
					title: 'Overhang',
					dataIndex: 'overhang',
					key: 'overhang',
					render: (_, record) => this.renderCustomCell(record.dimensions.truck),
				},
				{
					title: 'Company',
					dataIndex: 'company',
					key: 'company',
					render: (_, record) => record.companyName
				},
				{
					title: 'Driver',
					dataIndex: 'driverName',
					key: 'driverName',
					render: (_, record) => record.driverName
				},
				{
					title: 'Truck',
					dataIndex: 'truck',
					key: 'truck',
					render: (_, record) => record.truck
				},
				{
					title: 'Permit',
					dataIndex: 'permitLoad',
					key: 'permitLoad',
					render: (_, record) => record.permitLoad === true && (
						<FaCheck size={24} />
					)
				},
				{
					title: 'Non-Permit',
					dataIndex: 'permitLoad',
					key: 'Non-Permit',
					render: (_, record) => record.permitLoad === false && (
						<FaCheck size={24} />
					)
				}
			],
			summary: [],
			companies: [],
			nonPermits: [],
			permits: [],
			permitColumns: [
				{
					title: 'Date & Time',
					dataIndex: 'date',
					key: 'date',
					render: (_, record) => moment(record.time).format('MMM DD, YYYY HH:mm')
				},
				{
					title: 'Rig Name',
					dataIndex: 'rigName',
					key: 'rigName',
					render: () => this.state.rig && this.state.rig.name,
				},
				{
					title: 'Rig Number',
					dataIndex: 'rigNumber',
					key: 'rigNumber',
					render: () => this.state.rig && this.state.rig.number,
				},
				{
					title: 'Created By',
					dataIndex: 'createdBy',
					key: 'createdBy',
					render: (_, record) => !isEmpty(record.createdBy) ? this.getUserDetails(record.createdBy) : ''
				},
				{
					title: 'Updated By',
					dataIndex: 'updatedBy',
					key: 'updatedBy',
					render: (_, record) => !isEmpty(record.updatedBy) ? this.getUserDetails(record.updatedBy) : ''
				},
				{
					title: 'Part',
					dataIndex: 'part',
					key: 'part',
					render: (part) => part && part.map((parts) => parts.name).join(),
				},
				{
					title: 'Task',
					dataIndex: 'task',
					key: 'task',
					render: (task) => task && task.map((tasks) => tasks).join(),
				},
				{
					title: 'Company',
					dataIndex: 'companyName',
					key: 'companyName',
					render: (_, record) => record.companyName
				},
				{
					title: 'Load Type',
					dataIndex: 'load_type',
					key: 'load_type',
					render: (_, record) => record.load_type
				},
				{
					title: 'Driver Name',
					dataIndex: 'driverName',
					key: 'driverName',
					render: (_, record) => record.driverName
				},
				{
					title: 'Truck',
					dataIndex: 'truck',
					key: 'truck',
					render: (_, record) => record.truck
				},
				{
					title: 'Arrived Time',
					dataIndex: 'arrived_time',
					key: 'arrived_time',
					render: (_, record) => record.arrived_location === true && moment(record.arrived_time).format('MMM DD, YYYY HH:mm')
				},
				{
					title: 'Description',
					dataIndex: 'description',
					key: 'description',
					render: (_, record) => record.description
				},
			],
			pusherNotes: [],
			pusherColumns: [
				{
					title: 'Date',
					dataIndex: 'date',
					key: 'date',
					render: (record) => record && moment(record).format('MMM DD, YYYY')
				},
				{
					title: 'Rig Name',
					dataIndex: 'rigName',
					key: 'rigName',
					render: () => this.state.rig && this.state.rig.name,
				},
				{
					title: 'Rig Number',
					dataIndex: 'rigNumber',
					key: 'rigNumber',
					render: () => this.state.rig && this.state.rig.number,
				},
				{
					title: 'Part',
					dataIndex: 'part',
					key: 'part',
					render: (part) => part && part.map((parts) => parts.name).join(),
				},
				{
					title: 'Task',
					dataIndex: 'task',
					key: 'task',
					render: (task) => task && task.map((tasks) => tasks).join(),
				},
				{
					title: 'Type',
					dataIndex: 'type',
					key: 'type',
					render: (_, record) => record.type
				},
				{
					title: 'Description',
					dataIndex: 'description',
					key: 'description',
					render: (_, record) => record.description
				},
				{
					title: 'Lessons',
					dataIndex: 'lessons',
					key: 'lessons',
					render: (_, record) => record.lessons
				},
				{
					title: 'Corrective Measures',
					dataIndex: 'measures',
					key: 'measures',
					render: (_, record) => record.measures
				},
			],
			pusherReports: [],
			rigpics: [],
			assets: [],
			jsa: [],
			selectedValue: ['load-list', 'task-notes', 'companies', 'equipments', 'non-permits', 'permits', 'pusher_notes', 'pusher_reports', 'rigpics', 'assets', 'jsa'],
			selectRigPics: 'all',
			selectAssetsPics: 'all',
			searchLists: { type: '', list: [] },
			createdBy: '',
			updatedBy: '',
			from: '',
			to: '',
			dateOfPics: '',
			dateOfAssets: '',
			equipmentOptions: [],
			users: props.auth.usersList,
			selectLoads: ''
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
		const { usersList } = this.props.auth;
		if (prevProps.auth.usersList.length !== usersList.length) {
			this.setState({ users: usersList });
		}
		const { rigsList } = this.props.rig;
		if (prevProps.rig.rigsList.length !== rigsList.length) {
			this.getRigDetails();
		}
	}

	renderCustomCell(record) {
		return (
			record.feet && (
				<p>{record.feet} ft. {record.inches} inch.</p>
			)
		);
	}

	getUserDetails(uid) {
		const { users } = this.state;
		const user = users.find(user => user.uid === uid);
		return user?.firstName + ' ' + user?.lastName;
	}

	componentDidMount() {
		this.setState({ Loader: true });
		this.getRigDetails();
	}

	getRigDetails() {const { rigsList } = this.props.rig;
		const temp = rigsList.find((item) => item.$key === this.state.id);
		if (temp) {
			this.setState({ rig: temp }, () => {
				this.getUserById(temp.uid, 'createdBy');
				this.getUserById(temp.updatedBy, 'updatedBy');
				this.getRigDownNotes();
				this.getRigUpNotes();
				this.getTaskNotes();
				this.getEquipmentOtions();
				this.getPusherNotes();
				this.getPusherReports();
				this.getRigpics();
				this.getAssets();
				this.getJSA();

				if (this.state.rig.releaseDate) {
					let newstartDate = moment(this.state.rig.releaseDate);
					newstartDate = this.state.rig.releaseDate.replace('Z', '');
					this.setState({ releaseDate: moment(newstartDate).valueOf() });
				}
				this.setState({ Loader: false });
			});
		}
	}

	getEquipmentOtions() {
		const db = firebase.firestore();
		const downRef = db.collection('equipmentOptions');
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					temp.push(data);
				}
			});
			this.getCompanies();
			this.setState({ equipmentOptions: temp });
		});
	}

	getRigDownNotes(from, to) {
		const db = firebase.firestore();
		let downRef;
		if ((isEmpty(from) && isEmpty(to)) || (isEmpty(from) && !isEmpty(to))) {
			downRef = db.collection('Rig-Down').where('rigId', '==', this.state.id).orderBy('time', 'desc');
		} else if (!isEmpty(from) && isEmpty(to)) {
			downRef = db.collection('Rig-Down').where('rigId', '==', this.state.id).where('date', '>=', from);
		} else {
			downRef = db.collection('Rig-Down').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [], nonPermit = [], permit = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						if (data.dimensions.width.feet === undefined) {
							data.dimensions.width = {};
						}
						if (data.dimensions.height.feet === undefined) {
							data.dimensions.height = {};
						}
						if (data.dimensions.length.feet === undefined) {
							data.dimensions.length = {};
						}
						if (data.dimensions.truck.feet === undefined) {
							data.dimensions.truck = {};
						}
						temp.push(data);
						if (data.permitLoad === false) {
							nonPermit.push(data);
						}
						if (data.permitLoad === true) {
							permit.push(data);
						}
					}
				}
			});
			temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
			this.setState({ down_notes: temp, nonPermits: nonPermit, permits: permit });
		});
	}

	getRigUpNotes(from, to) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let upRef;
		if ((isEmpty(from) && isEmpty(to)) || (isEmpty(from) && !isEmpty(to))) {
			upRef = db.collection('Rig-Up').where('rigId', '==', this.state.id).orderBy('time', 'desc');
		} else if (!isEmpty(from) && isEmpty(to)) {
			upRef = db.collection('Rig-Up').where('rigId', '==', this.state.id).where('date', '>=', from);
		} else {
			upRef = db.collection('Rig-Up').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		}
		upRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [], nonPermit = [], permit = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
						if (data.permitLoad === false) {
							nonPermit.push(data);
						}
						if (data.permitLoad === true) {
							permit.push(data);
						}
					}
				}
			});
			const totalNonPermits = this.state.nonPermits.concat(nonPermit);
			const totalPermits = this.state.permits.concat(permit);
			totalNonPermits.sort((a, b) => (a.time > b.time) ? -1 : 1);
			totalPermits.sort((a, b) => (a.time > b.time) ? -1 : 1);
			const totalNotes = this.state.down_notes.concat(temp);
			totalNotes.sort((a, b) => (a.time > b.time) ? -1 : 1);
			this.setState({
				notes: totalNotes,
				nonPermits: totalNonPermits,
				permits: totalPermits
			});

		});
	}

	getTaskNotes(from, to) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let downRef;
		if ((isEmpty(from) && isEmpty(to)) || (isEmpty(from) && !isEmpty(to))) {
			downRef = db.collection('drilling-rig').where('rigId', '==', this.state.id).where('type', '==', 'Summary').orderBy('time', 'desc');
		} else if (!isEmpty(from) && isEmpty(to)) {
			downRef = db.collection('drilling-rig').where('rigId', '==', this.state.id).where('type', '==', 'Summary').where('date', '>=', from);
		} else {
			downRef = db.collection('drilling-rig').where('rigId', '==', this.state.id).where('type', '==', 'Summary').where('date', '>=', from).where('date', '<=', to);
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});

			temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
			this.setState({ summary: temp });
		});
	}

	getCompanies(from, to) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let downRef;
		if ((isEmpty(from) && isEmpty(to)) || (isEmpty(from) && !isEmpty(to))) {
			downRef = db.collection('rigCompanies').where('rigId', '==', this.state.id).orderBy('createdAt', 'desc');
		} else if (!isEmpty(from) && isEmpty(to)) {
			downRef = db.collection('rigCompanies').where('rigId', '==', this.state.id).where('date', '>=', from);
		} else {
			downRef = db.collection('rigCompanies').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			temp.sort((a, b) => (a.date > b.date) ? -1 : 1);
			this.setState({ companies: temp });
		});
	}

	getAllEquipmentPrice(data) {
		return data.reduce((acc, base) => acc + this.getEquipmentTotalPrice(base), 0);
	}

	getEquipmentPrice(item) {
		const equipOptions = this.state.equipmentOptions;
		const index = equipOptions.map(option => option.$key).indexOf(item.$key);
		if (index !== -1) {
			const option = equipOptions[index];
			return `${option.price} / ${option.usage}`;
		} else {
			const selectedItem = this.filterForNoUsage(item);
			if (!isEmpty(selectedItem)) {
				return `${selectedItem.price} / ${selectedItem.usage}`;
			}
			return 0;
		}
	}

	getEquipmentTotalPrice(item) {
		const equipOptions = this.state.equipmentOptions;
		const index = equipOptions.map(option => option.$key).indexOf(item.$key);
		if (index !== -1) {
			const price = equipOptions[index].price;
			if (equipOptions[index].usage === 'Full Day') {
				if (item.usage === 'Full Day') {
					return item.quantity * price;
				}
				if (item.usage === '1/2 Day') {
					return item.quantity * (price / 2);
				}
				if (item.usage === 'Hourly') {
					return item.quantity * ((price / 12) * item.hours);
				}
			}
			if (equipOptions[index].usage === 'Hourly') {
				if (item.usage === 'Full Day') {
					return item.quantity * (price * 12);
				}
				if (item.usage === '1/2 Day') {
					return item.quantity * (price * 6);
				}
				if (item.usage === 'Hourly') {
					return item.quantity * (price * item.hours);
				}
			}
		} else {
			const selectedItem = this.filterForNoUsage(item);
			if (!isEmpty(selectedItem) && selectedItem.usage === 'Hourly') {
				return selectedItem.quantity * (selectedItem.price * 12);
			}
			return 0;
		}
		return 0;
	}

	filterForNoUsage(item) {
		const equipOptions = this.state.equipmentOptions;
		const equipment = equipOptions.filter((option) => {
			option.type = option.type.replace(/\s/g, '').toLowerCase();
			option.type = option.type.replace('(s)', '');

			item.type = item.type.replace(/\s/g, '').toLowerCase();

			if (option.type.includes(item.type)) {
				option.quantity = item.quantity;
				return option;
			}
			return null;
		});
		return equipment[0];
	}

	getPusherNotes(from, to) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let downRef;
		if ((isEmpty(from) && isEmpty(to)) || (isEmpty(from) && !isEmpty(to))) {
			downRef = db.collection('pusher').where('rigId', '==', this.state.id).orderBy('time', 'desc');
		} else if (!isEmpty(from) && isEmpty(to)) {
			downRef = db.collection('pusher').where('rigId', '==', this.state.id).where('date', '>=', from);
		} else {
			downRef = db.collection('pusher').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
			this.setState({ pusherNotes: temp });
		});
	}

	getPusherReports(from, to) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let downRef;
		if (!isEmpty(from) && !isEmpty(to)) {
			downRef = db.collection('rigReports').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		} else if (!isEmpty(from) && isEmpty(to)) {
			downRef = db.collection('rigReports').where('rigId', '==', this.state.id).where('date', '>=', from);
		} else {
			downRef = db.collection('rigReports').where('rigId', '==', this.state.id).orderBy('generatedOn', 'desc');
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true && data.reportType === 'pusher_report') {
						temp.push(data);
					}
				}
			});
			temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
			this.setState({ pusherReports: temp });
		});
	}

	getRigpics(from, to, type) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let downRef;
		if ((isEmpty(from) && isEmpty(to)) || (type === 'singleDate' && isEmpty(from)) || (isEmpty(from) && !isEmpty(to))) {
			downRef = db.collection('rigpics').where('rigId', '==', this.state.id);
		} else if (!isEmpty(from) && isEmpty(to)) {
			if (type === 'range') {
				downRef = db.collection('rigpics').where('rigId', '==', this.state.id).where('date', '>=', from);
			} else {
				downRef = db.collection('rigpics').where('rigId', '==', this.state.id).where('date', '==', from);
			}
		} else {
			downRef = db.collection('rigpics').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.setState({ rigpics: temp });
		});
	}

	getAssets(from, to, type) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let downRef;
		if ((isEmpty(from) && isEmpty(to)) || (type === 'singleDate' && isEmpty(from)) || (isEmpty(from) && !isEmpty(to))) {
			downRef = db.collection('assets').where('rigId', '==', this.state.id);
		} else if (!isEmpty(from) && isEmpty(to)) {
			if (type === 'range') {
				downRef = db.collection('assets').where('rigId', '==', this.state.id).where('date', '>=', from);
			} else {
				downRef = db.collection('assets').where('rigId', '==', this.state.id).where('date', '==', from);
			}
		} else {
			downRef = db.collection('assets').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.setState({ assets: temp });
		});
	}

	getJSA(from, to) {
		this.setState({ Loader: true });
		const db = firebase.firestore();
		let downRef;
		if ((isEmpty(from) && isEmpty(to)) || (isEmpty(from) && !isEmpty(to))) {
			downRef = db.collection('jobsafetyanalysis').where('rigId', '==', this.state.id);
		} else if (!isEmpty(from) && isEmpty(to)) {
			downRef = db.collection('jobsafetyanalysis').where('rigId', '==', this.state.id).where('date', '==', from);
		} else {
			downRef = db.collection('jobsafetyanalysis').where('rigId', '==', this.state.id).where('date', '>=', from).where('date', '<=', to);
		}
		downRef.get().then((querySnapshot) => {
			this.setState({ Loader: false });
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			temp.sort((a, b) => (a.time > b.time) ? -1 : 1);
			this.setState({ jsa: temp });
		});
	}

	getUserById(id, userType) {
		const { usersList } = this.props.auth;
		const temp = usersList.find((item) => item.uid === id);
		this.setState({ [userType]: temp });
	}

	handleChange = (value) => {
		this.setState({ selectedValue: value });
	};

	searchByPartOrTask = (type, lists, searchInput) => {
		if (!isEmpty(lists) && type === 'part') {
			for (let i = 0; i < lists.length; i++) {
				const data = lists[i].name && lists[i].name.toLowerCase().match(searchInput);
				if (data !== null && Array.isArray(data)) {
					return true;
				}
				if ((i + 1) === lists.length) {
					return false;
				}
			}
		}
		if (!isEmpty(lists) && type === 'task') {
			for (let i = 0; i < lists.length; i++) {
				const data = lists[i] && lists[i].toLowerCase().match(searchInput);
				if (data !== null && Array.isArray(data)) {
					return true;
				}
				if ((i + 1) === lists.length) {
					return false;
				}
			}
		}
	}

	searchEquipment(list, string) {
		const tempList = [];
		for (let i = 0; i < list.length; i++) {
			if (!isEmpty(list[i].equipment)) {
				let equipment = list[i].equipment[0].name;
				equipment = equipment.filter((obj) => { return obj.type.toLowerCase().match(string); });
				list[i].equipment[0].name = equipment;
				tempList.push(list[i]);
				if (i === (list.length - 1)) {
					return tempList;
				}
			}
		}
	}

	searchSelectedModules = (type, value, list) => {
		const string = value.toLowerCase();
		let tempList = [];
		if (type === 'loadList') {
			for (let i = 0; i < list.length; i++) {
				const data = (list[i].companyName && list[i].companyName.toLowerCase().match(string)) || (list[i].truck && list[i].truck.toLowerCase().match(string));
				if (data === null) {
					const res = this.searchByPartOrTask('part', list[i].part, string);
					if (res !== null && res === true) {
						tempList.push(list[i]);
					}
				}
				if (data !== null && Array.isArray(data)) {
					tempList.push(list[i]);
				}
			}
		}
		if (type === 'task-notes') {
			for (let i = 0; i < list.length; i++) {
				const data = (list[i].description && list[i].description.toLowerCase().match(string)) || (list[i].selectType && list[i].selectType.toLowerCase().match(string));
				if (data !== null && Array.isArray(data)) {
					tempList.push(list[i]);
				}
			}
		}
		if (type === 'companies') {
			for (let i = 0; i < list.length; i++) {
				const data = (list[i].name && list[i].name.toLowerCase().match(string)) || (list[i].role && list[i].role.toLowerCase().match(string));
				if (data !== null && data !== '') {
					tempList.push(list[i]);
				}
			}
		}
		if (type === 'equipments') {
			tempList = this.searchEquipment(list, string);
		}
		if (type === 'nonPermits' || type === 'permits') {
			for (let i = 0; i < list.length; i++) {
				const data = (list[i].companyName && list[i].companyName.toLowerCase().match(string)) || (list[i].load_type && list[i].load_type.toLowerCase().match(string)) ||
					(list[i].driverName && list[i].driverName.toLowerCase().match(string)) || (list[i].truck && list[i].truck.toLowerCase().match(string));
				if (data === null) {
					const part = this.searchByPartOrTask('part', list[i].part, string);
					if (part !== null && part === true) {
						tempList.push(list[i]);
					}
					if (part === false || isEmpty(part)) {
						const task = this.searchByPartOrTask('task', list[i].task, string);
						if (task !== null && task === true) {
							tempList.push(list[i]);
						}
					}
				}
				if (data !== null && Array.isArray(data)) {
					tempList.push(list[i]);
				}
			}
		}
		if (type === 'pusherNotes') {
			for (let i = 0; i < list.length; i++) {
				const data = (list[i].description && list[i].description.toLowerCase().match(string));
				if (isEmpty(data)) {
					const part = this.searchByPartOrTask('part', list[i].part, string);
					if (part !== null && part === true) {
						tempList.push(list[i]);
					}
					if (part === false || isEmpty(part)) {
						const task = this.searchByPartOrTask('task', list[i].task, string);
						if (task !== null && task === true) {
							tempList.push(list[i]);
						}
					}
				}
				if (!isEmpty(data) && Array.isArray(data)) {
					tempList.push(list[i]);
				}
			}
		}
		if (type === 'pusherReports') {
			for (let i = 0; i < list.length; i++) {
				const data = (list[i].title && list[i].title.toLowerCase().match(string)) || (list[i].pusher_name && list[i].pusher_name.toLowerCase().match(string)) || (list[i].pusher_last_name && list[i].pusher_last_name.toLowerCase().match(string));
				if (data !== null && Array.isArray(data)) {
					tempList.push(list[i]);
				}
			}
		}
		let search;
		if (isEmpty(string)) {
			if (type === 'equipments') {
				this.getCompanies();
			}
			search = {
				type: '',
				list: []
			};
		} else {
			search = {
				type: type,
				list: tempList
			};
		}
		this.setState({ searchLists: search });
	}

	searchAllModules = (type, value) => {
		const { notes, summary, companies, nonPermits, permits, pusherNotes, pusherReports } = this.state;
		const string = value.toLowerCase();
		const notesSearch = [];
		const summarySearch = [];
		const companiesSearch = [];
		const nonPermitsSearch = [];
		const permitsSearch = [];
		const pusherNotesSearch = [];
		const pusherReportsSearch = [];
		let equipmentsSearch = [];
		for (let i = 0; i < notes.length; i++) {
			const data = (notes[i].companyName && notes[i].companyName.toLowerCase().match(string)) || (notes[i].truck && notes[i].truck.toLowerCase().match(string));
			if (data === null) {
				const res = this.searchByPartOrTask('part', notes[i].part, string);
				if (res !== null && res === true) {
					notesSearch.push(notes[i]);
				}
			}
			if (data !== null && Array.isArray(data)) {
				notesSearch.push(notes[i]);
			}
		}
		for (let i = 0; i < summary.length; i++) {
			const data = (summary[i].description && summary[i].description.toLowerCase().match(string)) || (summary[i].selectType && summary[i].selectType.toLowerCase().match(string));
			if (data !== null && Array.isArray(data)) {
				summarySearch.push(summary[i]);
			}
		}
		for (let i = 0; i < companies.length; i++) {
			const data = (companies[i].name && companies[i].name.toLowerCase().match(string)) || (companies[i].role && companies[i].role.toLowerCase().match(string));
			if (data !== null && data !== '') {
				companiesSearch.push(companies[i]);
			}
		}
		equipmentsSearch = this.searchEquipment(companies, string);
		for (let i = 0; i < nonPermits.length; i++) {
			const data = (nonPermits[i].companyName && nonPermits[i].companyName.toLowerCase().match(string)) || (nonPermits[i].load_type && nonPermits[i].load_type.toLowerCase().match(string)) ||
				(nonPermits[i].driverName && nonPermits[i].driverName.toLowerCase().match(string)) || (nonPermits[i].truck && nonPermits[i].truck.toLowerCase().match(string));
			if (data === null) {
				const part = this.searchByPartOrTask('part', nonPermits[i].part, string);
				if (part !== null && part === true) {
					nonPermitsSearch.push(nonPermits[i]);
				}
				if (part === false || isEmpty(part)) {
					const task = this.searchByPartOrTask('task', nonPermits[i].task, string);
					if (task !== null && task === true) {
						nonPermitsSearch.push(nonPermits[i]);
					}
				}
			}
			if (data !== null && Array.isArray(data)) {
				nonPermitsSearch.push(nonPermits[i]);
			}
		}
		for (let i = 0; i < permits.length; i++) {
			const data = (permits[i].companyName && permits[i].companyName.toLowerCase().match(string)) || (permits[i].load_type && permits[i].load_type.toLowerCase().match(string)) ||
				(permits[i].driverName && permits[i].driverName.toLowerCase().match(string)) || (permits[i].truck && permits[i].truck.toLowerCase().match(string));
			if (data === null) {
				const part = this.searchByPartOrTask('part', permits[i].part, string);
				if (part !== null && part === true) {
					permitsSearch.push(permits[i]);
				}
				if (part === false || isEmpty(part)) {
					const task = this.searchByPartOrTask('task', permits[i].task, string);
					if (task !== null && task === true) {
						permitsSearch.push(permits[i]);
					}
				}
			}
			if (data !== null && Array.isArray(data)) {
				permitsSearch.push(permits[i]);
			}
		}
		for (let i = 0; i < pusherNotes.length; i++) {
			const data = (pusherNotes[i].description && pusherNotes[i].description.toLowerCase().match(string));
			if (isEmpty(data)) {
				const part = this.searchByPartOrTask('part', pusherNotes[i].part, string);
				if (part !== null && part === true) {
					pusherNotesSearch.push(pusherNotes[i]);
				}
				if (part === false || isEmpty(part)) {
					const task = this.searchByPartOrTask('task', pusherNotes[i].task, string);
					if (task !== null && task === true) {
						pusherNotesSearch.push(pusherNotes[i]);
					}
				}
			}
			if (data !== null && Array.isArray(data)) {
				pusherNotesSearch.push(pusherNotes[i]);
			}
		}
		for (let i = 0; i < pusherReports.length; i++) {
			const data = (pusherReports[i].title && pusherReports[i].title.toLowerCase().match(string)) || (pusherReports[i].pusher_name && pusherReports[i].pusher_name.toLowerCase().match(string)) || (pusherReports[i].pusher_last_name && pusherReports[i].pusher_last_name.toLowerCase().match(string));
			if (data !== null && Array.isArray(data)) {
				pusherReportsSearch.push(pusherReports[i]);
			}
		}
		let search;
		if (isEmpty(string)) {
			if (equipmentsSearch && equipmentsSearch.length > 0) {
				this.getCompanies();
			}
			search = {
				type: ''
			};
		} else {
			search = {
				type: type,
				notesSearch: notesSearch,
				summarySearch: summarySearch,
				companiesSearch: companiesSearch,
				nonPermitsSearch: nonPermitsSearch,
				permitsSearch: permitsSearch,
				pusherNotesSearch: pusherNotesSearch,
				pusherReportsSearch: pusherReportsSearch,
				equipmentsSearch: equipmentsSearch
			};
		}
		this.setState({ searchLists: search });
	}

	onChangeCheckbox = (type, value) => {
		const { selectLoads } = this.state;
		if (type === 'rigpics') {
			this.setState({ selectRigPics: value });
			this.getRigpics();
		}
		if (type === 'assets') {
			this.setState({ selectAssetsPics: value });
		}
		if (type === 'loadList') {
			if (selectLoads !== value) {
				this.setState({ selectLoads: value });
				this.filterLoads(value === 'permit' ? true : false);
			} else {
				this.setState({ selectLoads: '' });
				const search = {
					type: '',
					list: []
				};
				this.setState({ searchLists: search });
			}
		}
	}

	filterLoads = (value) => {
		const { notes } = this.state;
		const notesSearch = [];
		for (let i = 0; i < notes.length; i++) {
			if (notes[i].permitLoad === value) {
				notesSearch.push(notes[i]);
			}
		}
		const search = {
			type: 'loadList',
			list: notesSearch
		};
		this.setState({ searchLists: search });
	}

	onDateChange = (type, value) => {
		if (type === 'rigpics') {
			this.setState({ dateOfPics: value === null ? value : moment(value) });
			this.getRigpics(value === null ? undefined : moment(value).format('YYYY-MM-DD'), undefined, 'singleDate');
		}
		if (type === 'assets') {
			this.setState({ dateOfAssets: value === null ? value : moment(value) });
			this.getAssets(value === null ? undefined : moment(value).format('YYYY-MM-DD'), undefined, 'singleDate');
		}
	}

	fromDateChange = (value) => {
		const { to } = this.state;
		const date = value !== null ? moment(value).format('YYYY-MM-DD') : undefined;
		this.setState({ from: value === null ? undefined : date, dateOfPics: null, dateOfAssets: null });
		this.getRigDownNotes(date, to);
		this.getRigUpNotes(date, to);
		this.getTaskNotes(date, to);
		this.getCompanies(date, to);
		this.getPusherNotes(date, to);
		this.getPusherReports(date, to);
		this.getRigpics(date, to, 'range');
		this.getAssets(date, to, 'range');
		this.getJSA(date, to);
	}

	toDateChange = (value) => {
		const { from } = this.state;
		const date = value !== null ? moment(value).format('YYYY-MM-DD') : undefined;
		this.setState({ to: value === null ? undefined : date, dateOfPics: null, dateOfAssets: null });
		this.getRigDownNotes(from, date);
		this.getRigUpNotes(from, date);
		this.getTaskNotes(from, date);
		this.getCompanies(from, date);
		this.getPusherNotes(from, date);
		this.getPusherReports(from, date);
		this.getRigpics(from, date, 'range');
		this.getAssets(from, date, 'range');
		this.getJSA(from, date);
	}

	getUserForNotes = (id) => {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(id);
		if (userIndex !== -1) {
			return (users[userIndex].firstName + ' ' + users[userIndex].lastName);
		} else {
			return null;
		}
	}

	renderEquipments(companies) {
		return (
			!isEmpty(companies) && companies.map((company, index) => {
				return (
					<div key={index}>
						{company.equipment &&
							<p key={company.$key.toString()}><b>Company Name:</b> {company.name}</p>
						}
						{company.equipment && company.equipment.map((equipment, ind) => {
							return (
								<div key={ind.toString()}>
									<Row className='equipHeader equipContent'>
										<Col span={4}><b>Qty</b></Col>
										<Col span={5}><b>Equipment Type</b></Col>
										<Col span={5}><b>Usage</b></Col>
										<Col span={5}><b>Price</b></Col>
										<Col span={5}><b>Total Price</b></Col>
									</Row>
									{equipment.name.map((key, i) => {
										return (
											key.quantity !== '' &&
											<Row key={(key + ind + i).toString()} className='equipContent'>
												<Col span={4}>{key.quantity}</Col>
												<Col span={5}>{key.type}</Col>
												{key.usage === 'Hourly' ?
													<Col span={5}>{key.usage}({key.hours} hour)</Col>
													:
													!isEmpty(key.usage) ?
														<Col span={5}>{key.usage}</Col>
														:
														<Col span={5}><p>Full Day</p></Col>
												}
												<Col span={5}>{this.getEquipmentPrice(key)}</Col>
												<Col span={5}>{this.getEquipmentTotalPrice(key)}</Col>
											</Row>
										);
									})}
									<p><b>Total amount:</b> {this.getAllEquipmentPrice(equipment.name)}</p>
								</div>
							);
						})}
					</div>
				);
			})
		);
	}

	render() {
		const { rig, notes, columns, summary, companies, nonPermits, permits, permitColumns, pusherNotes, pusherColumns, pusherReports, rigpics,
			assets, jsa, selectedValue, searchLists, selectRigPics, selectAssetsPics, createdBy, updatedBy, dateOfPics, dateOfAssets, selectLoads, isPermitted } = this.state;
		const { Column } = Table;
		const { Option } = Select;

		if (rig === '') {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div>
				<div className="card-body">
					<Row className='rig-info-section'>
						<Col md={24} xl={12} span={24} >
							<h4 className="card-title">Rig Info</h4>
						</Col>
						{isPermitted &&
							<Col md={24} xl={12} span={24} className='create-report-section'>
								<button className="site-btn export-btn" onClick={() => this.props.history.push(`/rigs/create-report/${rig.$key}`)}>Create Report</button>
							</Col>
						}
					</Row>
					<Row>
						<Col md={12} xl={6} span={24} >
							<p><b>Job Number: </b>{rig.jobNumber}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Name: </b>{rig.name}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Number: </b>{rig.number}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Phone: </b>{rig.phoneNumber}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Email: </b>{rig.email}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Superintendent: </b>{rig.superintendent}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Manager: </b>{rig.manager}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p>
								<b>Rig Release Date: </b>
								{rig.releaseDate &&
									<>
										<Moment format="MMM DD, YYYY">{rig.releaseDate}</Moment>&nbsp;
										<Moment format='h:mm'>{rig.releaseDate}</Moment>
									</>
								}
							</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Rig Operator: </b>{rig.operator}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Operator Phone: </b>{rig.operatorPhone}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Operator Email: </b>{rig.operatorEmail}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Operator DSM: </b>{rig.operatorEngineer}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Move Type: </b>{rig.moveType}</p>
						</Col>
						<Col md={12} xl={6} span={24} >
							<p><b>Status: </b>{rig.status !== 'Canceled' ? rig.status : 'Cancelled'}</p>
						</Col>
						{createdBy ?
							<Col md={12} xl={6} span={24} >
								<p><b>Created By: </b>{createdBy.firstName + ' ' + createdBy.lastName}</p>
							</Col>
							: null}
						{updatedBy ?
							<Col md={12} xl={6} span={24} >
								<p><b>Updated By: </b>{updatedBy.firstName + ' ' + updatedBy.lastName}</p>
							</Col>
							: null}
					</Row>
				</div>
				<div className="theme-form card-body">
					<Row>
						<Col span={24}>
							<div id="DropdownFixPostion">
								<h4 className="card-title">Filter (Modules)</h4>
								<Select
									style={{ width: '100%' }}
									dropdownClassName="custom-select-option"
									className="custom-select-multiple"
									getPopupContainer={() => document.getElementById('DropdownFixPostion')}
									defaultValue={selectedValue}
									size="large"
									mode="multiple"
									onChange={(value) => this.handleChange(value)}
								>
									<Option value="load-list">Load List</Option>
									<Option value="task-notes">Task/Notes List</Option>
									<Option value="companies">Companies</Option>
									<Option value="equipments">Equipments</Option>
									<Option value="non-permits">Non-Permit Loads</Option>
									<Option value="permits">Permit Loads</Option>
									<Option value="pusher_notes">Pusher Notes</Option>
									<Option value="pusher_reports">Pusher Reports</Option>
									<Option value="rigpics">Rig Pics</Option>
									<Option value="assets">Assets</Option>
									<Option value="jsa">JSA</Option>
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className="theme-form card-body">
					<Row>
						<Col span={24}>
							<div id="DropdownFixPostion">
								<h4 className="card-title">Search</h4>
							</div>
						</Col>
					</Row>
					<Row className={'rowContent'}>
						<Col span={12} className={'searchInput'}>
							<span className="small-title">Search Rigs info:</span>
							<Search
								placeholder="Search..."
								onSearch={value => this.searchAllModules('all', value)}
								className="rigSearch custom-search"
								allowClear={true}
								onChange={data => this.searchAllModules('all', data.target.value)}
							/>
						</Col>
						<Col className="custom-date-picker" span={6} id="DropdownFixPostion">
							<span className="small-title">From:</span>
							<DatePicker
								onChange={(data) => this.fromDateChange(data)}
								className="date_picker"
							/>
						</Col>
						<Col className="custom-date-picker" span={6} id="DropdownFixPostion1">
							<span className="small-title">To:</span>
							<DatePicker
								onChange={(data) => this.toDateChange(data)}
								className="date_picker"
							/>
						</Col>
					</Row>
				</div>
				{selectedValue.includes('load-list') &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Load List ({
									searchLists.type === 'all' ? searchLists.notesSearch.length : searchLists.type === 'loadList' ? searchLists.list.length : notes.length
								})</h4>
							</Col>
							<Col span={12}>
								<Checkbox className="custom-checkbox" checked={selectLoads === 'permit' ? true : false} onChange={() => this.onChangeCheckbox('loadList', 'permit')}>Permit</Checkbox>
								<Checkbox className="custom-checkbox" checked={selectLoads === 'non-permit' ? true : false} onChange={() => this.onChangeCheckbox('loadList', 'non-permit')}>Non Permit</Checkbox>
								<Search
									placeholder="Seach Part, Company, Truck..."
									onSearch={value => this.searchSelectedModules('loadList', value, notes)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('loadList', data.target.value, notes)}
								/>
							</Col>
						</Row>
						<Table columns={columns} dataSource={searchLists.type === 'all' ? searchLists.notesSearch : searchLists.type === 'loadList' ? searchLists.list : notes} rowKey={record => record.$key} />
					</div>
				}
				{selectedValue.includes('task-notes') &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Task / Notes List ({
									searchLists.type === 'all' ? searchLists.summarySearch.length : searchLists.type === 'task-notes' ? searchLists.list.length : summary.length
								})</h4>
							</Col>
							<Col span={12}>
								<Search
									placeholder="Search Description, Type..."
									onSearch={value => this.searchSelectedModules('task-notes', value, summary)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('task-notes', data.target.value, summary)}
								/>
							</Col>
						</Row>
						<Table dataSource={searchLists.type === 'all' ? searchLists.summarySearch : searchLists.type === 'task-notes' ? searchLists.list : summary} rowKey={(record, index) => record.$key + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
							<Column title="Created Date" dataIndex="date" render={(_, record) => <Moment format="MMM DD YYYY HH:mm">{record.updatedAt}</Moment>} />
							<Column title="Updated Date" dataIndex="createdAt" render={(_, record) =>
								!isEmpty(record.updatedAt) ? <Moment format="MMM DD YYYY HH:mm">{record.updatedAt}</Moment> : '-'} />
							<Column title="Rig Name" dataIndex="rigName" render={() => rig && rig.name} />
							<Column title="Rig Number" dataIndex="rigNumber" render={() => rig && rig.number} />
							<Column title="Type" dataIndex="selectType" key="selectType" />
							<Column title="Description" dataIndex="description" key="description" />
							<Column title="LOS Name" dataIndex="updatedBy" render={(_, record) =>
								!isEmpty(record.updatedBy) ? <p>{this.getUserForNotes(record.updatedBy)}</p> : !isEmpty(record.createdBy) ? <p>{this.getUserForNotes(record.createdBy)}</p> : '-'} />
						</Table>
					</div>
				}

				{selectedValue.includes('companies') &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Companies ({
									searchLists.type === 'all' ? searchLists.companiesSearch.length : searchLists.type === 'companies' ? searchLists.list.length : companies.length
								})</h4>
							</Col>
							<Col span={12}>
								<Search
									placeholder="Search Company, Role..."
									onSearch={value => this.searchSelectedModules('companies', value, companies)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('companies', data.target.value, companies)}
								/>
							</Col>
						</Row>
						<Table dataSource={searchLists.type === 'all' ? searchLists.companiesSearch : searchLists.type === 'companies' ? searchLists.list : companies} rowKey={(record, index) => record.$key + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
							<Column title="Date" dataIndex="date" render={(record) => <Moment format="MMM DD, YYYY">{record}</Moment>} />
							<Column title="Rig Name" dataIndex="rigName" render={() => rig && rig.name} />
							<Column title="Rig Number" dataIndex="rigNumber" render={() => rig && rig.number} />
							<Column title="Day" dataIndex="day" key="day" />
							<Column title="Company Name" dataIndex="name" key="name" />
							<Column title="Role" dataIndex="role" key="role" />
							<Column title="Phone" dataIndex="phone" key="phone" />
						</Table>
					</div>
				}

				{selectedValue.includes('equipments') && companies.length > 0 &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Equipments (Based on each company)</h4>
							</Col>
							<Col span={12}>
								<Search
									placeholder="Search Equipment Type"
									onSearch={value => this.searchSelectedModules('equipments', value, companies)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('equipments', data.target.value, companies)}
								/>
							</Col>
						</Row>
						{searchLists.type === 'all' ? this.renderEquipments(searchLists.equipmentsSearch) : searchLists.type === 'equipments' ? this.renderEquipments(searchLists.list) : this.renderEquipments(companies)}
					</div>
				}
				{selectedValue.includes('non-permits') &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Non-Permit Loads ({
									searchLists.type === 'all' ? searchLists.nonPermitsSearch.length : searchLists.type === 'nonPermits' ? searchLists.list.length : nonPermits.length
								})</h4>
							</Col>
							<Col span={12}>
								<Search
									placeholder="Search Part, Task, Company, Load Type, Driver Name, Truck..."
									onSearch={value => this.searchSelectedModules('nonPermits', value, nonPermits)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('nonPermits', data.target.value, nonPermits)}
								/>
							</Col>
						</Row>
						<Table columns={permitColumns} dataSource={searchLists.type === 'all' ? searchLists.nonPermitsSearch : searchLists.type === 'nonPermits' ? searchLists.list : nonPermits} rowKey={record => record.$key} />
					</div>
				}
				{selectedValue.includes('permits') &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Permit Loads ({
									searchLists.type === 'all' ? searchLists.permitsSearch.length : searchLists.type === 'permits' ? searchLists.list.length : permits.length
								})</h4>
							</Col>
							<Col span={12}>
								<Search
									placeholder="Search Part, Task, Company Name, Load Type, Driver Name, Truck..."
									onSearch={value => this.searchSelectedModules('permits', value, permits)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('permits', data.target.value, permits)}
								/>
							</Col>
						</Row>
						<Table columns={permitColumns} dataSource={searchLists.type === 'all' ? searchLists.permitsSearch : searchLists.type === 'permits' ? searchLists.list : permits} rowKey={record => record.$key} />
					</div>
				}
				{selectedValue.includes('pusher_notes') &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Pusher Notes ({
									searchLists.type === 'all' ? searchLists.pusherNotesSearch.length : searchLists.type === 'pusherNotes' ? searchLists.list.length : pusherNotes.length
								})</h4>
							</Col>
							<Col span={12}>
								<Search
									placeholder="Search Part, Task, Description"
									onSearch={value => this.searchSelectedModules('pusherNotes', value, pusherNotes)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('pusherNotes', data.target.value, pusherNotes)}
								/>
							</Col>
						</Row>
						<Table columns={pusherColumns} dataSource={searchLists.type === 'all' ? searchLists.pusherNotesSearch : searchLists.type === 'pusherNotes' ? searchLists.list : pusherNotes} rowKey={record => record.$key} />
					</div>
				}
				{selectedValue.includes('pusher_reports') &&
					<div className="card-body">
						<Row>
							<Col span={12}>
								<h4 className="card-title">Pusher Reports ({
									searchLists.type === 'all' ? searchLists.pusherReportsSearch.length : searchLists.type === 'pusherReports' ? searchLists.list.length : pusherReports.length
								})</h4>
							</Col>
							<Col span={12}>
								<Search
									placeholder="Search Title, First Name, Last Name"
									onSearch={value => this.searchSelectedModules('pusherReports', value, pusherReports)}
									style={{ width: '100%', marginBottom: '16px' }}
									className="rigSearch"
									allowClear={true}
									onChange={data => this.searchSelectedModules('pusherReports', data.target.value, pusherReports)}
								/>
							</Col>
						</Row>
						<Table dataSource={searchLists.type === 'all' ? searchLists.pusherReportsSearch : searchLists.type === 'pusherReports' ? searchLists.list : pusherReports} rowKey={(record, index) => record.$key + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
							<Column title="Date" dataIndex="date" render={(record) => <Moment format="MMM DD, YYYY">{record}</Moment>} />
							<Column title="Rig Name" dataIndex="rigName" render={() => rig && rig.name} />
							<Column title="Rig Number" dataIndex="rigNumber" render={() => rig && rig.number} />
							<Column title="Title" dataIndex="title" key="title" />
							<Column title="First Name" dataIndex="pusher_name" key="pusher_name" />
							<Column title="Last Name" dataIndex="pusher_last_name" key="pusher_last_name" />
						</Table>
					</div>
				}
				{selectedValue.includes('rigpics') &&
					<div className="card-body theme-form">
						<h4 className="card-title">Rig Pics</h4>
						<Checkbox className="custom-checkbox" checked={selectRigPics === 'all' ? true : false} onChange={() => this.onChangeCheckbox('rigpics', 'all')}>All</Checkbox>
						<Checkbox className="custom-checkbox" checked={selectRigPics === 'date' ? true : false} onChange={() => this.onChangeCheckbox('rigpics', 'date')}>Date</Checkbox>
						{selectRigPics === 'date' &&
							<div className="custom-date-picker" id="DropdownFixPostion2" style={{ padding: '16px 0', maxWidth: '260px' }}>
								<DatePicker
									value={dateOfPics}
									className="date_picker"
									onChange={(value) => this.onDateChange('rigpics', value)}
								/>
							</div>
						}
						{rigpics.length === 0 ?
							<h6 className="error-text text-center">No rigpics are found.</h6>
							:
							<div className="rigImage-row" id="rig_pics_list">
								{rigpics.map((picture, index) => {
									return (
										<div className="rigImage-box" key={index}>
											<div className="inner-area">
												{picture.url ?
													<img src={picture.url} alt="rigpics" className="rig-photo" />
													:
													<img src={rec} onLoad={this.onLoad} alt="no_rigpics" className="rig-photo" />
												}
												<h4 className="text-center"><Moment format="MMM DD, YYYY">{picture.date}</Moment></h4>
												<p className="picSection-time text-center"><Moment format="h:mm a">{picture.date}</Moment></p>
											</div>
										</div>
									);
								})}
							</div>
						}
					</div>
				}
				{selectedValue.includes('assets') &&
					<div className="card-body theme-form">
						<h4 className="card-title">Assets</h4>
						<Checkbox className="custom-checkbox" checked={selectAssetsPics === 'all' ? true : false} onChange={() => this.onChangeCheckbox('assets', 'all')}>All</Checkbox>
						<Checkbox className="custom-checkbox" checked={selectAssetsPics === 'date' ? true : false} onChange={() => this.onChangeCheckbox('assets', 'date')}>Date</Checkbox>
						{selectAssetsPics === 'date' &&
							<div className="custom-date-picker" id="DropdownFixPostion3" style={{ padding: '16px 0', maxWidth: '260px' }}>
								<DatePicker							
									value={dateOfAssets}
									className="date_picker"
									onChange={(value) => this.onDateChange('assets', value)}
								/>
							</div>
						}
						{assets.length === 0 ?
							<h6 className="text-center error-text">No assets are found.</h6>
							:
							<div className="rigImage-row" id="rig_pics_list">
								{assets.map((picture, index) => {
									return (
										<div className="rigImage-box" key={index}>
											<div className="inner-area">
												{picture.url ?
													<img src={picture.url} alt="rigpics" className="rig-photo" />
													:
													<img src={rec} onLoad={this.onLoad} alt="no_rigpics" className="rig-photo" />
												}
												<h4 className="text-center"><Moment format="MMM DD, YYYY">{picture.date}</Moment></h4>
												<p className="picSection-time text-center"><Moment format="h:mm a">{picture.date}</Moment></p>
											</div>
										</div>
									);
								})}
							</div>
						}
					</div>
				}
				{selectedValue.includes('jsa') &&
					<div className="card-body">
						<h4 className="card-title">Job Safety Reports ({jsa.length})</h4>
						<Table dataSource={jsa} rowKey={(record, index) => record.$key + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
							<Column title="Date" dataIndex="date" render={(record) => <Moment format="MMM DD, YYYY">{record}</Moment>} />
							<Column title="Reason" dataIndex="reason" key="reason" />
							<Column title="Title" dataIndex="title" key="title" />
							<Column title="All safe" dataIndex="allSafe" key="allSafe" />
							<Column title="Shift" dataIndex="shift" key="shift" />
							<Column title="At risk condition" dataIndex="behaviorObserved" key="behaviorObserved" />
							<Column title="Hazard observed" dataIndex="hazardObserved" key="hazardObserved" />
							<Column title="Proper PPE" dataIndex="ppeObserved" key="ppeObserved" />
							<Column title="Environment Impacted" dataIndex="environmentImpacted" key="environmentImpacted" />
							<Column title="Weather Affect" dataIndex="weatherAffect" key="weatherAffect" />
							<Column title="Caught" dataIndex="caught" key="caught" />
							<Column title="Corrective Actions" dataIndex="correctiveActions" key="correctiveActions" />
							<Column title="h2sLevel" dataIndex="h2sLevel" key="h2sLevel" />
							<Column title="Responsible" dataIndex="responsible" key="responsible" />
						</Table>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(Rig));
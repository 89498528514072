import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../../services/firebase';
import '../../report.css';
import Moment from 'react-moment';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import rec from '../../../../assets/imgs/rec.png';
import loader from '../../../../assets/imgs/loader.gif';
import lightning_logo from '../../../../assets/imgs/lightning_logo.jpeg';
import { checkWritePermissions } from '../../../../utils/native';
import { isEmpty } from '../../../../utils/activity-logs';

class PusherReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			id: this.props.match.params.id,
			rig: '',
			report: '',
			load: false,
			user: props.auth.currentUser,
			date: { days: 0, hour: 0, minutes: 0 },
			pusherDownNotes: [],
			pusherUpNotes: [],
			otherNotes: [],
			users: props.auth.usersList,
			isLoading: false
		};
	}

	onLoad = () => {
		this.setState({
			load: true
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
		const { usersList } = this.props.auth;
		if (prevProps.auth.usersList.length !== usersList.length) {
			this.setState({ users: usersList });
		}
	}

	componentDidMount() {
		const db = firebase.firestore();
		this.setState({ isLoading: true });
		const rigs = db.collection('rigReports').where('$key', '==', this.state.id);
		rigs.get().then(async (querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists && doc.data().archive !== true) {
					temp.push(doc.data());
				}
			});
			this.setState({
				report: temp[0]
			});
			const reportDetails = temp[0];
			this.getRigDetails();
			this.getCurrentUser();

			const pusherResult = await this.getPusherByType(reportDetails.rigId, reportDetails.date, reportDetails.endDate);
			if (reportDetails.pusher_pics) {
				const downNotesPics = await this.getPusherPicsById(pusherResult.pusherDownNotes, this.state.users);
				const upNotesPics = await this.getPusherPicsById(pusherResult.pusherUpNotes, this.state.users);
				const otherNotesPics = await this.getPusherPicsById(pusherResult.otherNotes, this.state.users);

				if (downNotesPics.length > 0) {
					const pusherDownNotes = await this.getArrayByDates(downNotesPics);
					this.setState({ pusherDownNotes });
				}
				if (upNotesPics.length > 0) {
					const pusherUpNotes = await this.getArrayByDates(upNotesPics);
					this.setState({ pusherUpNotes });
				}
				if (otherNotesPics.length > 0) {
					const otherNotes = await this.getArrayByDates(otherNotesPics);
					this.setState({ otherNotes });
				}
			} else {
				if (pusherResult.pusherDownNotes.length > 0) {
					const pusherDownNotes = await this.getArrayByDates(pusherResult.pusherDownNotes);
					this.setState({ pusherDownNotes });
				}
				if (pusherResult.pusherUpNotes.length > 0) {
					const pusherUpNotes = await this.getArrayByDates(pusherResult.pusherUpNotes);
					this.setState({ pusherUpNotes });
				}
				if (pusherResult.otherNotes.length > 0) {
					const otherNotes = await this.getArrayByDates(pusherResult.otherNotes);
					this.setState({ otherNotes });
				}
			}
			this.setState({ isLoading: false });
		});
	}

	getPusherByType(rigId, selectedDate, endDate) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			let query;
			if (!endDate) {
				query = db.collection('pusher').where('rigId', '==', rigId).where('date', '==', selectedDate).orderBy('time', 'desc');
			} else {
				query = db.collection('pusher').where('rigId', '==', rigId).where('date', '>=', selectedDate).where('date', '<=', endDate);
			}
			query.get().then((querySnapshot) => {
				const pusherDownNotes = [], pusherUpNotes = [], otherNotes = [];
				if (!querySnapshot.empty) {
					querySnapshot.forEach(async note => {
						const item = note.data();
						item.$key = note.id;
						delete item.images;
						if (!item.archive) {
							if (item.type === 'rigDown') {
								pusherDownNotes.push(item);
							}
							if (item.type === 'rigUp') {
								pusherUpNotes.push(item);
							}
							if (isEmpty(item.type)) {
								otherNotes.push(item);
							}
						}
					});
				}
				pusherDownNotes.sort((a, b) => (a.time > b.time) ? -1 : 1);
				pusherUpNotes.sort((a, b) => (a.time > b.time) ? -1 : 1);
				otherNotes.sort((a, b) => (a.time > b.time) ? -1 : 1);
				resolve({ pusherDownNotes, pusherUpNotes, otherNotes });
			});
		});
	}

	getPusherPics(id, users) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection('pusher').doc(id).collection('images');
			query.get().then((querySnapshot) => {
				const pusherPics = [];
				querySnapshot.forEach(async note => {
					const item = note.data();
					item.$key = note.id;
					delete item.images;
					if (!item.archive) {
						const selected = users.filter((user) => item.createdBy === user.uid);
						if (selected.length > 0) {
							item.createdUser = selected[0];
						}
						pusherPics.push(item);
					}
				});
				resolve(pusherPics);
			});
		});
	}

	getArrayByDates = (list) => {
		return new Promise(async (resolve) => {
			const indexesArray = [], indexes = [];
			for (const i in list) {
				if (!list[i].archive) {
					const index = indexes.indexOf(list[i].date);
					if (index === -1) {
						indexes.push(list[i].date);
						const newIndex = indexes.indexOf(list[i].date);
						indexesArray[newIndex] = {
							title: list[i].createdAt,
							items: []
						};
						indexesArray[newIndex].items.push(list[i]);
					} else {
						if (!indexesArray[index]) {
							indexesArray[index] = {
								title: list[i].createdAt,
								items: []
							};
						}
						indexesArray[index].items.push(list[i]);
					}
				}
				if (parseInt(i) === (list.length - 1)) {
					resolve(indexesArray);
				}
			}
		});
	};

	getPusherPicsById = async (allPushers, users) => {
		const oldArray = [...allPushers];
		for (const i in oldArray) {
			const pics = await this.getPusherPics(oldArray[i].$key, users);
			oldArray[i].images = pics;
		}
		return oldArray;
	};

	getRigDetails() {
		const { report} = this.state;
		if (report.rigId) {
			const { rigsList } = this.props.rig;
			const temp = rigsList.find((item) => item.$key === report.rigId);
			if (temp.counters) {
				temp.loadcount = temp.counters.rigDown.nonPermits + temp.counters.rigUp.nonPermits +
				temp.counters.rigDown.permits + temp.counters.rigUp.permits;
			}
			this.setState({ rig: temp }, () => {
				this.rigmoveClock();
			});
		}
	}

	getCurrentUser() {
		const { report} = this.state;
		if (report.uid && this.props.auth.currentUser.$key !== report.uid) {
			const { usersList } = this.props.auth;
			const temp = usersList.find((item) => item.uid === report.uid);
			this.setState({ user: temp });
		}
	}

	rigmoveClock() {
		const { rig } = this.state;
		if ((rig.startClock && !rig.endClock) || (rig.startClock && rig.endClock === '')) {
			if (rig.startClock === 'startRigClock' && (rig.startRigClock === undefined || rig.startRigClock === '')) {
				this.noDateDiff();
			} else {
				const startDate = rig.startRigClock && moment(rig.startRigClock.replace('Z', ''));
				const currentDate = moment();
				this.calculateDateDiff(moment(startDate), currentDate, false);
			}
		}
		if (rig.startClock === 'startRigClock' && rig.endClock === 'stopRigClock') {
			if (rig.startRigClock && rig.stopRigClock && rig.startRigClock !== null && rig.stopRigClock !== '') {
				this.calculateDateDiff(rig.startRigClock, rig.stopRigClock, true);
			} else if (rig.stopRigClock === '') {
				const startDate = moment(rig.startRigClock);
				const currentDate = moment();
				this.calculateDateDiff(startDate, currentDate, true);
			} else {
				this.noDateDiff();
			}
		}
	}

	calculateDateDiff(date1, date2, utc) {
		const { date } = this.state;
		if (utc === true) {
			const release = moment(date1);
			const pickup = moment(date2);
			date.days = pickup.diff(release, 'days');
			date.hour = pickup.diff(release, 'hours') - (date.days * 24);
			date.minutes = +moment.utc(moment(pickup, 'HH:mm:ss').diff(moment(release, 'HH:mm:ss'))).format('mm');
			this.setState({ date });
		} else {
			const currentDate = moment(date2, 'MM-DD-YYYY HH:mm:ss').valueOf();
			const startDate = moment(date1, 'MM-DD-YYYY HH:mm:ss').valueOf();
			var temp = Math.abs(currentDate - startDate) / 1000;
			date.days = Math.floor(temp / 86400);
			date.hour = Math.floor(temp / 3600) % 24;
			date.minutes = Math.floor(temp / 60) % 60;
			this.setState({ date });
		}
	}

	noDateDiff() {
		const { date } = this.state;
		date.days = 0;
		date.hour = 0;
		date.minutes = 0;
		this.setState({ date });
	}

	rigmoveClockView() {
		const { rig, date } = this.state;
		return (
			<Col md={12} xl={12} span={24}>
				<div className="reportDetails">
					<h6 className="card-title">Rig Clock</h6>
					<div className="rigmoveClock">
						<p>
							{date.days > 0 && <span>{date.days} Days</span>}
							<span>{date.hour} Hrs {date.minutes} Mins</span>
						</p>
						<p>Elapsed since rig release on</p>
						{rig.releaseDate &&
							<p><Moment format='DD/MM/YYYY'>{rig.releaseDate}</Moment>
								<span> <Moment format='HH:mm'>{rig.releaseDate.replace('Z', '')}</Moment></span>
							</p>
						}
					</div>
				</div>
			</Col>
		);
	}

	render() {
		const { user, rig, report, isLoading, pusherDownNotes, pusherUpNotes, otherNotes, load } = this.state;
		if (!isLoading) {
			return (
				<div className="card-body">
					<div className="report-header">
						<h3 className="text-center no-mb">Report Details</h3>
					</div>
					<div className="report-content">
						<div className="rig-logo">
							<img src={lightning_logo} alt="rigmove_img" />
						</div>
						<h3 className="report-title-name text-center">{rig.name}</h3>
						{/* Rig details defined in the particular rig */}
						<div className="details-part">
							<Row className="row-details">
								<Col md={24} xl={24} span={24}>
									<h6 className="card-title">RIG #{rig.number} Summary Report</h6>
									<div className="report-details dataTexts">
										<p className="data-texts"><b>Date:</b> <Moment format='DD-MMM-YYYY'>{report.date}</Moment></p>
										{rig.releaseDate ? <p className="data-texts"><b>Start Move:</b> <Moment format='DD-MMM-YYYY'>{rig.releaseDate}</Moment></p> : null}
										<p className="data-texts"><b>Status:</b> {rig.status !== 'Canceled' ? rig.status : 'Cancelled'}</p>
										{(report.reportType === 'los_report' || report.reportType === 'update_report') && rig.jobNumber ?
											<p className="data-texts"><b>Job Number:</b> {rig.jobNumber}</p> : null}
										<p className="data-texts"><b>Rig Name:</b> {rig.name}</p>
										<p className="data-texts"><b>Rig #:</b> {rig.number}</p>
										{rig.afeNumber ? <p className="data-texts"><b>AFE #:</b> {rig.afeNumber}</p> : null}
										<p className="data-texts"><b>Phone Number:</b> {rig.phoneNumber}</p>
										{rig.email ? <p className="data-texts"><b>Contact Email:</b> {rig.email}</p> : null}
										{rig.superintendent ? <p className="data-texts"><b>Superintendent:</b> {rig.superintendent}</p> : null}
										{rig.manager ? <p className="data-texts"><b>Manager:</b> {rig.manager}</p> : null}
										{rig.type ? <p className="data-texts"><b>Rig Type:</b> {rig.type}</p> : null}
										{rig.oldLocationName ? <p className="data-texts"><b>Old Location Name/Well name:</b> {rig.oldLocationName}</p> : null}
										{rig.oldLocation ? <p className="data-texts"><b>GPS Location:</b> {rig.oldLocation}</p> : null}
										{rig.newLocationName ? <p className="data-texts"><b>New Location Name/Well Name:</b> {rig.newLocationName}</p> : null}
										{rig.operator ? <p className="data-texts"><b>Operator:</b> {rig.operator}</p> : null}
										{rig.operatorEmail ? <p className="data-texts"><b>Operator Email:</b> {rig.operatorEmail}</p> : null}
										{rig.operatorPhone ? <p className="data-texts"><b>Operator Phone:</b> {rig.operatorPhone}</p> : null}
										{rig.operatorEngineer ? <p className="data-texts"><b>Operator Drilling Engineer:</b> {rig.operatorEngineer}</p> : null}
										{rig.moveType ? <p className="data-texts"><b>Move Type:</b> {rig.moveType}</p> : null}
										{rig.estMiles ? <p className="data-texts"><b>Est.miles to move:</b> {rig.estMiles}</p> : null}
										{rig.estDays ? <p className="data-texts"><b>Est. days to move:</b> {rig.estDays}</p> : null}
										{rig.actualDaysOfRigmove ? <p className="data-texts"><b>Actual move days:</b> {rig.actualDaysOfRigmove}</p> : null}
										{rig.NptHours ? <p className="data-texts"><b>NPT time:</b> {rig.NptHours}</p> : null}
										{rig.WoNptHours ?
											<p className="data-texts"><b>Move time W/O NPT:</b> {rig.WoNptHours} / {rig.WoNptDays}</p> : null}
										{rig.bumpPlug ? <p className="data-texts"><b>Bump the plug:</b> <Moment format="DD/MM/YY h:mm a">{rig.bumpPlug}</Moment></p> : null}
										{rig.releaseDate ? <p className="data-texts"><b>Target Release Date:</b> <Moment format="DD/MM/YY h:mm a">{rig.releaseDate}</Moment></p> : null}
										{rig.mastLowered ? <p className="data-texts"><b>Mast lowered:</b> <Moment format="DD/MM/YY h:mm a">{rig.mastLowered}</Moment></p> : null}
										{rig.mastRaised ? <p className="data-texts"><b>Mast Raised:</b> <Moment format="DD/MM/YY h:mm a">{rig.mastRaised}</Moment></p> : null}
										{rig.subsLowered ? <p className="data-texts"><b>Subs lowered:</b> <Moment format="DD/MM/YY h:mm a">{rig.subsLowered}</Moment></p> : null}
										{rig.subsRaised ? <p className="data-texts"><b>Subs Raised:</b> <Moment format="DD/MM/YY h:mm a">{rig.subsRaised}</Moment></p> : null}
										{rig.nippleUpBope ? <p className="data-texts"><b>Nipple up BOPE:</b> <Moment format="DD/MM/YY h:mm a">{rig.nippleUpBope}</Moment></p> : null}
										{rig.beginBope ? <p className="data-texts"><b>Begin BOPE testing:</b> <Moment format="DD/MM/YY h:mm a">{rig.beginBope}</Moment></p> : null}
										{rig.finishBope ? <p className="data-texts"><b>Finish BOPE testing:</b> <Moment format="DD/MM/YY h:mm a">{rig.finishBope}</Moment></p> : null}
										{rig.totalBopeHours ? <p className="data-texts"><b>Total Bope Time:</b> <Moment format="DD/MM/YY h:mm a">{rig.totalBopeHours}</Moment></p> : null}
										{rig.pickupBha ? <p className="data-texts"><b>Pickup BHA:</b> <Moment format="DD/MM/YY h:mm a">{rig.pickupBha}</Moment></p> : null}
										{rig.spud ? <p className="data-texts"><b>Spud:</b> <Moment format="DD/MM/YY h:mm a">{rig.spud}</Moment></p> : null}
										{rig.mastPickupHours ? <p className="data-texts"><b>Mast up to pickup BHA:</b> {rig.mastPickupHours}</p> : null}
										{rig.releasePickupHours ? <p className="data-texts"><b>Release to pickup BHA:</b> {rig.releasePickupHours} / {rig.releasePickupDays}</p> : null}
										{rig.plugBumpHours ? <p className="data-texts"><b>Plug bump to pickup BHA:</b> {rig.plugBumpHours} / {rig.plugBumpDays}</p> : null}
									</div>
									<hr className="report-line" />
								</Col>
								{/* --------------------------- Rigmove Clock --------------------------- */}
								{rig && report.reportType !== 'update_report' && this.rigmoveClockView()}

								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									<h6 className="card-title">Truck Pusher - {report.pusher_name} {report.pusher_last_name} </h6>
									<h6 className="card-title">Pusher Notes</h6>
								</Col>
								{/* <---------------------------- RigDown ----------------------------> */}
								{pusherDownNotes.length !== 0 &&
									<Col md={24} xl={24} span={24}>
										<hr className="report-line" />
										<h6 className="card-title">Rig Down</h6>
										{pusherDownNotes.map((notes) => {
											return (
												<>
													<p className="data-texts"><Moment format="DD/MM/YYYY">{notes.title}</Moment></p>
													{notes && notes.items && notes.items.map((pusher) => {
														return (
															<>
																<div className="report-details dataTexts" key={pusher.$key}>
																	<p className="data-texts"><Moment format="HH:mm">{pusher.time}</Moment></p>
																	{!pusher.edited && pusher.description && <p className="data-texts">Description: {pusher.description}</p>}
																	{pusher.edited && pusher.entries && pusher.entries.map((entry) => {
																		return (
																			entry.description &&
																			<>
																				<p>{moment(entry.time).format('h:mm a')}: New Entry</p>
																				<p>Description: {entry.description}</p>
																			</>
																		);
																	})}
																	{pusher.lessons && <p className="data-texts">Lessons Learned: {pusher.lessons}</p>}
																	{pusher.measures && <p className="data-texts">Corrective Measures: {pusher.measures}</p>}
																</div>
																{pusher.totalRigDown && <p className="data-texts rig-down">Rig Down: {pusher.totalRigDown}</p>}
																{pusher.totalRigUp && <p className="data-texts rig-up">Rig Up: {pusher.totalRigUp}</p>}
															</>
														);
													})}
												</>
											);
										})}
									</Col>
								}
								{/* <---------------------------- RigUp ----------------------------> */}
								{pusherUpNotes.length !== 0 &&
									<Col md={24} xl={24} span={24}>
										<hr className="report-line" />
										<h6 className="card-title">Rig Up</h6>
										{pusherUpNotes.map((notes) => {
											return (
												<>
													<p className="data-texts"><Moment format="DD/MM/YYYY">{notes.title}</Moment></p>
													{notes && notes.items && notes.items.map((pusher) => {
														return (
															<div className="report-details dataTexts" key={pusher.$key}>
																<p className="data-texts"><Moment format="HH:mm">{pusher.time}</Moment></p>
																{!pusher.edited && pusher.description && <p className="data-texts">Description: {pusher.description}</p>}
																{pusher.edited && pusher.entries && pusher.entries.map((entry) => {
																	return (
																		entry.description &&
																		<>
																			<p>{moment(entry.time).format('h:mm a')}: New Entry</p>
																			<p>Description: {entry.description}</p>
																		</>
																	);
																})}
																{pusher.lessons && <p className="data-texts">Lessons Learned: {pusher.lessons}</p>}
																{pusher.measures && <p className="data-texts">Corrective Measures: {pusher.measures}</p>}
															</div>
														);
													})}
												</>
											);
										})}
									</Col>
								}
								{/* <---------------------------- Other ----------------------------> */}
								{otherNotes.length !== 0 &&
									<Col md={24} xl={24} span={24}>
										<hr className="report-line" />
										<h6 className="card-title">Rig</h6>
										{otherNotes.map((notes) => {
											return (
												<>
													<p className="data-texts"><Moment format="DD/MM/YYYY">{notes.title}</Moment></p>
													{notes && notes.items && notes.items.map((pusher) => {
														return (
															<div className="report-details dataTexts" key={pusher.$key}>
																<p className="data-texts"><Moment format="HH:mm">{pusher.time}</Moment></p>
																{!pusher.edited && pusher.description && <p className="data-texts">Description: {pusher.description}</p>}
																{pusher.edited && pusher.entries && pusher.entries.map((entry) => {
																	return (
																		entry.description &&
																		<>
																			<p>{moment(entry.time).format('h:mm a')}: New Entry</p>
																			<p>Description: {entry.description}</p>
																		</>
																	);
																})}
																{pusher.lessons && <p className="data-texts">Lessons Learned: {pusher.lessons}</p>}
																{pusher.measures && <p className="data-texts">Corrective Measures: {pusher.measures}</p>}
															</div>
														);
													})}
												</>
											);
										})}
									</Col>
								}
								{/* --------------------------- LoadCount --------------------------- */}
								{rig &&
									<Col md={12} xl={12} span={24}>
										<div className="reportDetails">
											<h6 className="card-title">Total Load Count: {rig.loadcount ? rig.loadcount : 0}</h6>
										</div>
									</Col>
								}

								{/* --------------------------- Pusher Pics --------------------------- */}
								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									<h6 className="card-title">Pusher Pics</h6>
								</Col>
								{/* --------------------------- Rig Down --------------------------- */}
								{pusherDownNotes.length !== 0 &&
									<Col md={24} xl={24} span={24}>
										<hr className="report-line" />
										<h6 className="card-title">Rig Down</h6>
										<div className="rig-pic-report">
											{pusherDownNotes.map((notes) => {
												return (
													<>
														<h6 className="date-rig-pics"><Moment format="MMM DD, YYYY">{notes.title}</Moment></h6>
														<div className="rig-pics-row">
															{notes && notes.items && notes.items.map((pusher) => {
																return (
																	<div className="rig-box" style={{ 'display': 'block' }} key={pusher.$key}>
																		<p><Moment format="HH:mm">{pusher.time}</Moment></p>
																		{pusher.images && pusher.images.map((image, index) => {
																			return (
																				<div className="rig-box-inner" key={index}>
																					<div className="image-part">
																						{load ?
																							<img src={image.url} alt="rigpics" />
																							:
																							<img src={rec} onLoad={this.onLoad} alt="no_rigpics" />
																						}
																					</div>
																					<div className="detail-part">
																						{image.createdBy && <p>Captured By: {image.createdUser.firstName} {image.createdUser.lastName}</p>}
																					</div>
																				</div>
																			);
																		})}
																	</div>
																);
															})}
														</div>
													</>
												);
											})}
										</div>
									</Col>
								}
								{/* --------------------------- Rig Up --------------------------- */}
								{pusherUpNotes.length !== 0 &&
									<Col md={24} xl={24} span={24}>
										<hr className="report-line" />
										<h6 className="card-title">Rig Up</h6>
										<div className="rig-pic-report">
											{pusherUpNotes.map((notes) => {
												return (
													<>
														<h6 className="date-rig-pics"><Moment format="MMM DD, YYYY">{notes.title}</Moment></h6>
														<div className="rig-pics-row">
															{notes && notes.items && notes.items.map((pusher) => {
																return (
																	<div className="rig-box" style={{ 'display': 'block' }}  key={pusher.$key}>
																		<p><Moment format="HH:mm">{pusher.time}</Moment></p>
																		{pusher.images && pusher.images.map((image, index) => {
																			return (
																				<div className="rig-box-inner" key={index}>
																					<div className="image-part">
																						{load ?
																							<img src={image.url} alt="rigpics" />
																							:
																							<img src={rec} onLoad={this.onLoad} alt="no_rigpics" />
																						}
																					</div>
																					<div className="detail-part">
																						{image.createdBy && <p>Captured By: {image.createdUser.firstName} {image.createdUser.lastName}</p>}
																					</div>
																				</div>
																			);
																		})}
																	</div>
																);
															})}
														</div>
													</>
												);
											})}
										</div>
									</Col>
								}
								{/* --------------------------- Other --------------------------- */}
								{otherNotes.length !== 0 &&
									<Col md={24} xl={24} span={24}>
										<hr className="report-line" />
										<h6 className="card-title">Rig</h6>
										<div className="rig-pic-report">
											{otherNotes.map((notes) => {
												return (
													<>
														<h6 className="date-rig-pics"><Moment format="MMM DD, YYYY">{notes.title}</Moment></h6>
														<div className="rig-pics-row">
															{notes && notes.items && notes.items.map((pusher) => {
																return (
																	<div className="rig-box" style={{ 'display': 'block' }} key={pusher.$key}>
																		<p><Moment format="HH:mm">{pusher.time}</Moment></p>
																		{pusher.images && pusher.images.map((image, index) => {
																			return (
																				<div className="rig-box-inner" key={index}>
																					<div className="image-part">
																						{load ?
																							<img src={image.url} alt="rigpics" />
																							:
																							<img src={rec} onLoad={this.onLoad} alt="no_rigpics" />
																						}
																					</div>
																					<div className="detail-part">
																						{image.createdBy && <p>Captured By: {image.createdUser.firstName} {image.createdUser.lastName}</p>}
																					</div>
																				</div>
																			);
																		})}
																	</div>
																);
															})}
														</div>
													</>
												);
											})}
										</div>
									</Col>
								}
								<Col md={24} xl={24} span={24}>
									<hr className="report-line" />
									{user &&
										<div className='reportDetails'>
											<p><b>Completed By:</b> {user.firstName} {user.lastName}</p>
											<p><b>Email address:</b> {user.email}</p>
											<p className='p-last-child'><b>Generated on:</b> <Moment format="MMM DD, YYYY">{report.generatedOn}</Moment> @ <Moment format='h:mm a'>{report.generatedOn}</Moment></p>
										</div>
									}
								</Col>
							</Row>
						</div>
						<div className="copy-right-content">
							<p>Copyright 2018 - all rights reserved by RigMoveApp</p>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(PusherReport));


import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASEURL;
const headers = {
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE'
};

export const sendEmail = (to, subject, message) => {
	return axios.post(baseUrl + '/sendEmail', { to: to, subject: subject, message: message }, headers);
};

export const changePassword = (selectedUID, password) => {
	return axios.post(baseUrl + '/updatePassword', { uid: selectedUID, password: password } , headers);
};

export const updateEmail = (selectedUID, email) => {
	return axios.post(baseUrl + '/updateEmail', { uid: selectedUID, email: email } , headers);
};

export const sendRigPic = (emailDetails) => {
	return axios.post(baseUrl + '/sendRigPic', emailDetails);
};

export const fileAttachment = (selectedReport) => {
	// return axios.post(baseUrl + '/fileAttachment', selectedReport);
	return axios.post(baseUrl + '/fileAttachment-v2', { data: selectedReport }, headers);
};

export const generateReport = (data, reportType, folderName) => {
	return axios.post(baseUrl + '/generateReport-v2', { data: data, reportType: reportType, folderName: folderName }, headers);
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import firebase from '../../services/firebase';
import rec from '../../assets/imgs/rec.png';
import { withRouter } from 'react-router-dom';
import loader from '../../assets/imgs/loader.gif';
import { Tabs } from 'antd';
import './pic-list.css';
import { isEmpty } from '../../utils/activity-logs';
import { checkPermissions, checkWritePermissions } from '../../utils/native';
import ErrorPage from '../error-page';
import * as rigActions from '../../redux/actions/rigActions';

const { TabPane } = Tabs;
class PicListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkPermissions(props, 'rigPics'),
			isPermitted1: checkPermissions(props, 'pusher'),
			isWritePermitted: checkWritePermissions(props, 'rigPics'),
			isWritePermitted1: checkWritePermissions(props, 'pusher'),
			loaded: true,
			pusherPicsLoaded: true,
			selectedTab: 1
		};
	}
	componentDidMount() {
		const { rigsPicsList, pusherPicsList } = this.props.rig;
		if (rigsPicsList.length === 0) {
			this.getRigpics();
		}
		if (pusherPicsList.length === 0) {
			this.getPusher();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({
				isPermitted: checkPermissions(this.props, 'rigPics'), isPermitted1: checkPermissions(this.props, 'pusher'),
				isWritePermitted: checkWritePermissions(this.props, 'rigPics'), isWritePermitted1: checkWritePermissions(this.props, 'pusher')
			});
		}
	}

	getRigpics() {
		this.setState({ loaded: false });
		const db = firebase.firestore();
		const picsRef = db.collection('rigpics').orderBy('createdAt', 'desc');
		picsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.props.dispatchRigsPicsList(temp);
			this.setState({ loaded: true });
		});
	}

	async getPusher() {
		this.setState({ pusherPicsLoaded: false });
		const db = firebase.firestore();
		const pusherPicsRef = db.collection('pusher').where('archive', '==', false).where('hasImages', '==', true).orderBy('createdAt', 'desc');
		const batchSize = 100;
		let temp = [];

		const fetchBatch = async (lastVisible = null) => {
			try {
				let query = pusherPicsRef.limit(batchSize);
				if (lastVisible) {
					query = query.startAfter(lastVisible);
				}

				const querySnapshot = await query.get();

				// Return when no more documents are available
				if (querySnapshot.empty) {
					return;
				}

				for (const doc of querySnapshot.docs) {
					if (doc.exists) {
						const data = doc.data();
						data.$key = doc.id;
						temp = await this.getPusherPics(doc.id, temp);
					}
				}

				// Fetch the next batch recursively
				const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
				this.props.dispatchPusherPicsList(temp);
				await fetchBatch(lastDoc);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching paginated data:', error);
			}
		};

		// Start fetching the first batch
		await fetchBatch();
		this.setState({ pusherPicsLoaded: true });
	}

	getPusherPics(id, temp) {
		return new Promise((resolve) => {
			const db = firebase.firestore();
			const pusherRef = db.collection('pusher').doc(id).collection('images').orderBy('id', 'asc');
			pusherRef.get().then((querySnapshot) => {
				querySnapshot.forEach(async (doc) => {
					if (doc.exists) {
						const data = doc.data();
						data.$key = id;
						data.createdAt = !isEmpty(data.updatedAt) ? data.updatedAt : data.id;
						if (data.archive !== true) {
							temp.push(data);
						}
					}
				});
				resolve(temp);
			});
		});
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key) });
	};

	picLists(rigpics, type) {
		const { loaded, isWritePermitted, isWritePermitted1 } = this.state;
		return (
			<div>
				{loaded === true &&
					<div className="rigImage-part">
						<div className="rigImage-row" id="rig_pics">
							{rigpics.map((picture, index) => {
								return (
									<div className="rigImage-box" key={index}>
										<div className="inner-area" onClick={() => type === 'rigpics' ?
											isWritePermitted &&
											this.props.history.replace(`/pics/${picture.$key}`)
											:
											isWritePermitted1 &&
											this.props.history.replace(`/pic/${picture.$key}`)}>
											{picture.url ?
												<img src={picture.url} alt="rigpics" className="rig-photo" />
												:
												<img src={rec} onLoad={this.onLoad} alt="no_rigpics" className="rig-photo" />
											}
											<h4><Moment format="MMM DD, YYYY">{picture.createdAt}</Moment></h4>
											<p className='picSection-time'><Moment format="h:mm a">{picture.createdAt}</Moment></p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				}
			</div>
		);
	}

	render() {
		const { loaded, selectedTab, isPermitted, isPermitted1, pusherPicsLoaded } = this.state;
		const { rigsPicsList, pusherPicsList } = this.props.rig;

		if (loaded === false || (selectedTab === 2 && !pusherPicsLoaded)) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="card-tab-body">
				<div className="custom-tabs no-mt">
					<Tabs type="card" defaultActiveKey={selectedTab.toString()} onChange={this.onChange}>
						<TabPane tab={!isPermitted ? 'Rig Pics' : `Rig Pics (${rigsPicsList.length})`} key="1">
							{(selectedTab === 1 && isPermitted) ? this.picLists(rigsPicsList, 'rigpics') : <ErrorPage />}
						</TabPane>
						<TabPane tab={!isPermitted1 ? 'Truck Pusher Pics' : `Truck Pusher Pics (${pusherPicsList.length})`} key="2">
							{(selectedTab === 2 && isPermitted1) ? this.picLists(pusherPicsList, 'truckPusherPics') : <ErrorPage />}
						</TabPane>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

const mapDispatchToProps = {
	dispatchRigsPicsList: (data) => rigActions.rigsPicsList(data),
	dispatchPusherPicsList: (data) => rigActions.pusherPicsList(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PicListing));

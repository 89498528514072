/* eslint-disable no-useless-escape */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../../services/firebase';
import lightning_logo from '../../assets/imgs/lightning_logo.jpeg';
import './login.css';
import moment from 'moment';
import { isEmpty } from '../../utils/activity-logs';
import { connect } from 'react-redux';
import * as authActions from '../../redux/actions/authActions';
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			email: '',
			password: '',
			validateInput: false,
			emailError: false,
			passwordError: false,
			errorMsg: '',
			counter: 0,
			stopTime: null,
			isDisabled: false
		};
		this.onValueChange = this.onValueChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.checkDuration();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.isLoggedIn === false && this.props.auth.isLoggedIn === true) {
			window.location.reload();
			this.props.history.push('/dashboard');
		}
	}

	checkDuration() {
		if (!isEmpty(localStorage.getItem('lockedTime'))) {
			const stopTime = parseInt(localStorage.getItem('lockedTime'));
			const stopAt = moment(stopTime).diff(moment(), 'minutes') * 60000;
			this.setState({ isDisabled: true });
			setTimeout(() => {
				this.setState({ isDisabled: false, counter: 0, errorMsg: '' });
				localStorage.removeItem('lockedTime');
				localStorage.removeItem('counter');
			}, stopAt);
		}
	}

	onValueChange(key, event) {
		this.setState({ [key]: event.target.value });
	}

	isValid() {
		const { email, password } = this.state;
		const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		let flag = false;

		if (email === '' || email === null || email === undefined || reg.test(email) === false) {
			this.setState({ emailError: true });
			flag = true;
		} else {
			this.setState({ emailError: false });
		}
		if (password === '' || password === null || password === undefined) {
			this.setState({ passwordError: true });
			flag = true;
		} else {
			this.setState({ passwordError: false });
		}

		if (flag === true) {
			return false;
		}

		this.setState({ emailError: false, passwordError: false });
		return true;
	}

	async onSubmit(event) {
		event.preventDefault();
		const { email, password, counter } = this.state;
		if (!this.isValid()) {
			this.setState({ validateInput: true });
			return;
		}
		this.setState({ errorMsg: '' });

		if (navigator.onLine === true) {
			firebase.auth().signInWithEmailAndPassword(email, password)
				.then((response) => this.checkLogin(response))
				.catch((error) => {
					if (counter < 3) {
						this.setState({ counter: counter + 1 });
						localStorage.setItem('counter', counter);
					}
					if (counter === 3) {
						localStorage.setItem('lockedTime', (moment().add(5, 'minutes').valueOf().toString()));
						localStorage.setItem('email', email);
						this.checkDuration();
						this.setState({ errorMsg: 'Contact Administrator to change password!' });
					} else {
						if (error.message[0] === '{') {
							const parsedMessage = JSON.parse(error.message);
							if (parsedMessage?.error && parsedMessage?.error?.message && parsedMessage?.error?.message === 'INVALID_LOGIN_CREDENTIALS') {
								this.setState({ errorMsg: 'Email or password entered is wrong !' });
							} else {
								this.setState({ errorMsg: parsedMessage?.error?.messages });
							}
						} else if (error.message) {
							this.setState({ errorMsg: error.message });
						} else {
							this.setState({ errorMsg: 'Email or password entered is wrong !' });
						}
					}
				});
		} else {
			this.setState({ errorMsg: 'Please check your network connectivity!' });
		}
	}

	async checkLogin(response) {
		if (response.user && response.user.uid) {
			const db = firebase.firestore();
			const login = await db.collection('users').where('uid', '==', response.user.uid);
			const querySnapshot = await login.get();
			let data = {};
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					data = doc.data();
					data.$key = doc.id;
					if (data.userType === 'owner') {
						localStorage.setItem('owner', true);
						localStorage.setItem('admin', true);
					}
					if (data.userType === 'admin') {
						localStorage.setItem('admin', true);
					}
					localStorage.setItem('firstName', data.firstName);
					localStorage.setItem('lastName', data.lastName);
					localStorage.setItem('fullName', data.firstName + ' ' + data.lastName);
				}
			});
			if (data.archive !== true && (data.userType === 'admin' || data.userType === 'owner')) {
				if (data.verified === true) {
					localStorage.setItem('user', response.user.uid);
					this.props.dispatchLogin(data);
				} else {
					this.setState({ errorMsg: 'Account is not approved by admin !' });
				}
			} else {
				if (data.userType !== 'admin') {
					this.setState({ errorMsg: 'You don\'t have access to Admin Panel !' });
				} else {
					this.setState({ errorMsg: 'Your account is deleted by admin !' });
				}
				await firebase.auth().signOut().then(() => {
					localStorage.clear();
				});
			}
		}
	}

	render() {
		const { email, password, validateInput, emailError, passwordError, errorMsg, isDisabled } = this.state;
		return (
			<div className='page-login'>
				<div className="login-part">
					<div className='login-header'>
						<img className="site-logo" src={lightning_logo} alt="logo" />
					</div>
					<form className='loginForm' onSubmit={this.onSubmit}>
						<div className="input-field">
							<input placeholder="Email" type="email" name={email} onChange={(value) => this.onValueChange('email', value)} />
							{validateInput && emailError && <p className="validation-error">Please enter valid email !</p>}
						</div>

						<div className="input-field">
							<input placeholder="Password" type="password" name={password} onChange={(value) => this.onValueChange('password', value)} />
							{validateInput && passwordError && <p className="validation-error">Please enter valid password !</p>}
						</div>
						{errorMsg && <p className="text-center validation-error">{errorMsg}</p>}

						<button disabled={isDisabled} type="submit" className={isDisabled === true ? 'disableBtn' : 'submitBtn'}>Login</button>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

const mapDispatchToProps = {
	dispatchLogin: (data) => authActions.handleLogin(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Dropdown, Menu, Row, Col } from 'antd';
import lightning_logo from '../assets/imgs/lightning_logo.jpeg';
import { HiOutlineBell } from 'react-icons/hi';
import firebase from '../services/firebase';
import { connect } from 'react-redux';
import * as authActions from '../redux/actions/authActions';
import * as rigActions from '../redux/actions/rigActions';

const { Header } = Layout;

class HeaderBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shortName: (localStorage.getItem('fullName') && localStorage.getItem('fullName').at(0)) + (localStorage.getItem('lastName') && localStorage.getItem('lastName').at(0))
		};
	}

	logout = async() => {
		await firebase.auth().signOut().then(() => { 
			localStorage.clear();
			this.props.dispatchLogout();
			this.props.dispatchRigData();
		});
		this.props.history.push('/signin');
	}

	render() {
		const { shortName } = this.state;
		return (
			<Header className="site-header">
				<div className="inner-area">
					<Row>
						<Col span={12}>
							<div className="left-area">
								<img src={lightning_logo} className="site-logo" alt="logo" />
							</div>
						</Col>
						<Col span={12}>
							<div className="right-area">
								<HiOutlineBell className="notification-bell" size={24} />
								<Dropdown
									className="userdropwn"
									overlay={() =>
										<>
											<div className="dropdown-boxuser">
												<div className="user-name">
													<p>{localStorage.getItem('fullName')}</p>
												</div>
												<Menu className="user-menus">
													<Menu.Item className="menu-item logout-menu" onClick={() => this.logout()} >Logout</Menu.Item>
												</Menu>
											</div>
										</>
									}
									trigger={['click']}
								>
									<div className="user-profile-dropdown ant-dropdown-link">
										<div className="avatar-circle">{shortName}</div>
									</div>
								</Dropdown>
							</div>
						</Col>
					</Row>
				</div>
			</Header>
		);
	}
}

const mapDispatchToProps = {
	dispatchLogout: () => authActions.logout(),
	dispatchRigData: () => rigActions.resetRigData()
};

export default connect(null, mapDispatchToProps)(withRouter(HeaderBar));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, Table } from 'antd';
import firebase from '../../../../services/firebase';
import loader from '../../../../assets/imgs/loader.gif';
import '../groups.css';
import moment from 'moment';
import { checkWritePermissions } from '../../../../utils/native';

class SmartGroupDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'smartGroups'),
			isLoading: false,
			segmentId: props.match.params.id,
			groupId: props.match.params.group_id,
			group: undefined,
			admins: [],
			los: [],
			users: props.auth.usersList,
			user: props.auth.currentUser,
			userColumns: [
				{
					title: 'First Name',
					dataIndex: 'firstName',
					key: 'firstName'
				},
				{
					title: 'Last Name',
					dataIndex: 'lastName',
					key: 'lastName'
				},
				{
					title: 'Email',
					dataIndex: 'email',
					key: 'email'
				},
				{
					title: 'Phone Number',
					dataIndex: 'phoneNumber',
					key: 'phoneNumber'
				},
				{
					title: 'User Type',
					dataIndex: 'userType',
					key: 'userType'
				},
				{
					title: 'Created At',
					dataIndex: 'createdAt',
					key: 'createdAt',
					render: (createdAt) => (createdAt && moment(createdAt).format('MMM DD, YYYY'))
				},
				{
					title: 'Verified',
					dataIndex: 'verified',
					key: 'verified'
				},
				{
					title: 'Country Code',
					dataIndex: 'countryCode',
					key: 'countryCode'
				},
				{
					title: 'Employee ID',
					dataIndex: 'employeeID',
					key: 'employeeID'
				},
				{
					title: 'Shop/Office/Field',
					dataIndex: 'selectedShop',
					key: 'selectedShop',
					render: (selectedShop) => selectedShop && selectedShop.map((selectedShops) => selectedShops).join(),
				},
				{
					title: 'Selected Location',
					dataIndex: 'selectedLocation',
					key: 'selectedLocation',
					render: (selectedLocation) => selectedLocation && selectedLocation.map((selectedLocations) => selectedLocations).join(),
				},
				{
					title: 'Employment Start Date',
					dataIndex: 'employmentStartDate',
					key: 'employmentStartDate',
					render: (employmentStartDate) => (employmentStartDate && moment(employmentStartDate).format('MMM DD, YYYY'))
				},
				{
					title: 'Last Evaluation Date',
					dataIndex: 'lastEvaluationDate',
					key: 'lastEvaluationDate',
					render: (lastEvaluationDate) => (lastEvaluationDate && moment(lastEvaluationDate).format('MMM DD, YYYY'))
				},
				{
					title: 'Group',
					dataIndex: 'group',
					key: 'group',
					render: (group) => group && group.map((groups) => groups).join(),
				},
				{
					title: 'Direct Manager',
					dataIndex: 'directManager',
					key: 'directManager'
				},
				{
					title: 'Title_',
					dataIndex: 'title_',
					key: 'title_'
				},
				{
					title: 'Term Date',
					dataIndex: 'termDate',
					key: 'termDate',
					render: (termDate) => (termDate && moment(termDate).format('MMM DD, YYYY'))
				},
				{
					title: 'Original Phone Number',
					dataIndex: 'originalPhoneNo',
					key: 'originalPhoneNo'
				},
				{
					title: 'Personal Truck',
					dataIndex: 'personalTruck',
					key: 'personalTruck'
				},
				{
					title: 'Los Housing',
					dataIndex: 'losHousing',
					key: 'losHousing',
					render: (_, record) => record.losHousing === true && 'YES'
				},
				{
					title: 'Mentor Program',
					dataIndex: 'mentorProgram',
					key: 'mentorProgram',
					render: (_, record) => record.mentorProgram === true && 'YES'
				},
				{
					title: 'Onboarding',
					dataIndex: 'onboarding',
					key: 'onboarding',
					render: (_, record) => record.onboarding === true && 'YES'
				}
			],
		};
	}

	componentDidMount() {
		this.getAllAdmins();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'smartGroups') });
		}
	}

	avatarFunction() {
		const { group } = this.state;
		return (
			<div className='avatar-table-part'>
				{group.administeredUsers && group.administeredUsers.map((administeredUsers, index) => {
					return (
						index < 3 ?
							<div className="avatar-table" key={index.toString()}>{administeredUsers}</div> :
							index === 3 &&
							<div className="avatar-table number" key={index.toString()}>+ {group.administeredUsers.length - 3}</div>
					);
				})
				}
			</div>
		);
	}

	getSections = () => {
		const { admins, los, segmentId, groupId } = this.state;
		
		const db = firebase.firestore();
		if (segmentId !== undefined) {
			const segmentsRef = db.collection('segments').orderBy('createdAt', 'desc');
			segmentsRef.get().then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					this.setState({ isLoading: false });
					if (doc.exists) {
						const data = doc.data();
						data.$key = doc.id;
						if (data.archive !== true && data.$key === segmentId) {
							if (data.groups && data.groups.length > 0) {
								const groups = data.groups.filter((item) => {
									return item.archive !== true;
								});

								groups.map((group, index) => {
									if (parseInt(groupId) === index) {
										group.administeredUsers = [];
										const administeredUsers = [], adminUsers = [], joinedUsers = [];
										group.administeredBy.map((administered) => {
											const userIndex = admins.map(admin => admin.uid).indexOf(administered);
											adminUsers.push(admins[userIndex]);
											return administeredUsers.push(admins[userIndex].firstName[0].toUpperCase() + admins[userIndex].lastName[0].toUpperCase());
										});
										// Admins with avatar name
										group.administeredUsers = administeredUsers;
										// Admins with all user details
										// group.adminUsers = adminUsers;
										// All LOS joined users
										group.joinedUsers.map((joined) => {
											const userIndex = los.map(admin => admin.uid).indexOf(joined);
											return joinedUsers.push(los[userIndex]);
										});
										// group.joinedUsers = joinedUsers;
										this.setState({ users: adminUsers.concat(joinedUsers) });
										// Get created user
										const userIndex = admins.map(admin => admin.uid).indexOf(group.createdByUid);
										group.createdByUser = admins[userIndex].firstName + ' ' + admins[userIndex].lastName;
										this.setState({ group: group });
									}
									return group;
								});
							}
						}
					}
				});
			});
		}
	}

	getAllAdmins = () => {
		const { usersList } = this.props.auth;
		const admins = usersList.filter((item) => (item.userType === 'admin' || item.userType === 'owner'));
		const los = usersList.filter((item) => item.userType === 'LOS');
		this.setState({ admins, los }, () =>{
			this.getSections();
		});
	}

	render() {
		const { isLoading, group, userColumns, users } = this.state;

		
		if (isLoading === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<>
				{group !== undefined &&
					<div>
						<div className="card-body">
							<Row>
								<Col span={12}>
									<div className="groups-icon-title">
										<h4 className="card-title no-mb">All users group</h4>
									</div>
									<p>Enter group description</p>
								</Col>
								<Col span={12}>
									<p>Group admins</p>
									{group.administeredUsers &&
										this.avatarFunction()
									}
								</Col>
							</Row>
							<hr />
							<Row>
								<Col span={8}>
									<h1>{group.administeredBy.length + group.joinedUsers.length}</h1>
									<p>Employees in group</p>
								</Col>
								<Col span={8}>
									<h1>0</h1><span> / {group.administeredBy.length + group.joinedUsers.length}</span>
									<p>Connected to app</p>
								</Col>
								<Col span={8}>
									<p>Created on {moment(group.createdAt).format('MMM DD, YYYY')}</p>
									<p>By: {group.createdByUser} </p>
								</Col>
							</Row>
							<hr />
							<div>
								<p>
									<span>Group filtered by </span>&nbsp;&nbsp;
									<span>No filters created - </span>
									<span>Group contains all users</span>
								</p>
							</div>
						</div>

						<div className="card-body">
							<Table columns={userColumns} dataSource={users} rowKey={record => record.uid} scroll={{ x: true }} />
						</div>
					</div>
				}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(SmartGroupDetails));
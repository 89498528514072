import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../../../services/firebase';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import loader from '../../../../assets/imgs/loader.gif';
import { Table, Row, Col } from 'antd';
import { FaCheck } from 'react-icons/fa';
import { isEmpty } from '../../../../utils/activity-logs';
import { checkWritePermissions } from '../../../../utils/native';


class LoadReports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'reports'),
			id: this.props.match.params.id,
			rig: '',
			report: '',
			rigdown_notes: [],
			total_notes: [],
			users: props.auth.usersList,
			columns: [
				{
					title: 'Date & Time',
					dataIndex: 'time',
					key: 'time',
					render: (_, record) => moment(record.time).format('MMM DD, YYYY HH:mm')
				},
				{
					title: 'Created By',
					dataIndex: 'createdBy',
					key: 'createdBy',
					render: (_, record) => !isEmpty(record.createdBy) ? this.getUserDetails(record.createdBy) : ''
				},
				{
					title: 'Updated By',
					dataIndex: 'updatedBy',
					key: 'updatedBy',
					render: (_, record) => !isEmpty(record.updatedBy) ? this.getUserDetails(record.updatedBy) : ''
				},
				{
					title: 'Company',
					dataIndex: 'companyName',
					key: 'companyName',
					render: (_, record) => record.companyName
				},
				{
					title: 'Truck #',
					dataIndex: 'truck',
					key: 'truck',
					render: (_, record) => record.truck
				},
				{
					title: 'Driver Name',
					dataIndex: 'driverName',
					key: 'driverName',
					render: (_, record) => record.driverName
				},
				{
					title: 'Load Type',
					dataIndex: 'load_type',
					key: 'load_type',
					render: (_, record) => record.load_type
				},
				{
					title: 'Rig Part',
					dataIndex: 'part',
					key: 'part',
					render: (part) => part && part.map((parts) => parts.name).join(),
				},
				{
					title: 'Non-Permit',
					dataIndex: 'permitLoad',
					key: 'Non-Permit',
					render: (_, record) => record.permitLoad === false && (
						<FaCheck size={28} style={{ width: '60%' }} />
					)
				},
				{
					title: 'Permit',
					dataIndex: 'permitLoad',
					key: 'permitLoad',
					render: (_, record) => record.permitLoad === true && (
						<FaCheck size={28} style={{ width: '60%' }} />
					)
				},
				{
					title: 'Width',
					dataIndex: 'width',
					key: 'width',
					render: (_, record) => this.renderCustomCell(record.dimensions.width),
				},
				{
					title: 'Height',
					dataIndex: 'height',
					key: 'height',
					render: (_, record) => this.renderCustomCell(record.dimensions.height),
				},
				{
					title: 'Length',
					dataIndex: 'length',
					key: 'length',
					render: (_, record) => this.renderCustomCell(record.dimensions.length),
				},
				{
					title: 'Overhang',
					dataIndex: 'overhang',
					key: 'overhang',
					render: (_, record) => this.renderCustomCell(record.dimensions.truck),
				},
				{
					title: '# Tractor Axles',
					dataIndex: 'tractorAxles',
					key: 'tractorAxles',
					render: (_, record) => record.tractorAxles
				},
				{
					title: '# Trailer Axles',
					dataIndex: 'axles',
					key: 'axles',
					render: (_, record) => record.axles
				},
				{
					title: '# Stinger Axles',
					dataIndex: 'stingerAxles',
					key: 'stingerAxles',
					render: (_, record) => record.stingerAxles
				},
				{
					title: '# Jeep Axles',
					dataIndex: 'jeepAxles',
					key: 'jeepAxles',
					render: (_, record) => record.jeepAxles
				},
			],
			rigDownList: [],
			rigUpList: []
		};
	}

	renderCustomCell(record) {
		return (
			record.feet && (
				<p>{record.feet} ft. {record.inches} inch.</p>
			)
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'reports') });
		}
		const { usersList } = this.props.auth;
		if (prevProps.auth.usersList.length !== usersList.length) {
			this.setState({ users: usersList });
		}
	}

	componentDidMount() {
		const db = firebase.firestore();
		const rigs = db.collection('rigReports').where('$key', '==', this.state.id);
		rigs.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists && doc.data().archive !== true) {
					temp.push(doc.data());
				}
			});
			db.collection('Rig-Down').where('rigId', '==', temp[0].rigId).where('archive', '==', false).orderBy('time', 'desc')
				.get().then((querySnapshot) => {
					this.setState({ rigDownList: querySnapshot.docs.map((doc) => {return {...doc.data(), '$key': doc.id};}) }, () => {
						this.getRigDownNotes();
					});
				});
			db.collection('Rig-Up').where('rigId', '==', temp[0].rigId).where('archive', '==', false).orderBy('time', 'desc')
				.get().then((querySnapshot) => {
					this.setState({ rigUpList: querySnapshot.docs.map((doc) => {return {...doc.data(), '$key': doc.id};}) }, () => {
						this.getRigUPNotes();
					});
				});
			this.setState({
				report: temp[0]
			}, () => {
				this.getRigDetails();
			});
		});
	}

	getRigDetails() {
		const { report } = this.state;
		if (this.state.report.rigId) {
			const { rigsList } = this.props.rig;
			const temp = rigsList.find((item) => item.$key === report.rigId);
			this.setState({
				rig: temp,
			});
		}
	}

	/* ---------------------------- RigDown Notes ---------------------------- */
	getRigDownNotes() {
		const { report, rigDownList } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		const list = rigDownList.filter((item) => (item.date >= selectedDate) && (report.endDate === undefined || report.endDate === null || item.date <= report.endDate));
		this.setState({
			rigdown_notes: list
		});
	}

	/* ---------------------------- RigUp Notes ---------------------------- */
	getRigUPNotes() {
		const { report, rigUpList } = this.state;
		const selectedDate = moment(report.date).format('YYYY-MM-DD');
		const list = rigUpList.filter((item) => (item.date >= selectedDate) && (report.endDate === undefined || report.endDate === null || item.date <= report.endDate));
		this.setState({
			total_notes: this.state.rigdown_notes.concat(list)
		});
	}

	getUserDetails(uid) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(uid);
		if (userIndex !== -1) {
			return (users[userIndex].firstName + ' ' + users[userIndex].lastName);
		}
		return null;
	}

	render() {
		const { rig, columns, total_notes } = this.state;
		if (rig && total_notes) {
			return (
				<div className='reportContent'>
					<div className="card-body">
						{rig &&
							<div className="output-table-header">
								<Row>
									<Col md={12} xl={12} span={24}>
										<p><span>Rig Name: </span> {rig.name}</p>
									</Col>
									<Col md={12} xl={12} span={24}>
										<p><span>Rig #: </span> {rig.number}</p>
									</Col>
									<Col md={12} xl={12} span={24}>
										<p><span>Job #: </span> {rig.jobNumber}</p>
									</Col>
									<Col md={12} xl={12} span={24}>
										<p><span>Load Count: </span> {rig.counters ? (rig.counters.rigDown.nonPermits + rig.counters.rigUp.nonPermits + rig.counters.rigDown.permits + rig.counters.rigUp.permits) : (rig.loadcount ? rig.loadcount : 0)}</p>
									</Col>
								</Row>
							</div>
						}
						{total_notes.length > 0 &&
							<Table className='loadTable' columns={columns} dataSource={total_notes} rowKey={record => record.$key} />
						}
					</div>
				</div>
			);
		} else {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(LoadReports));

import { Form, Icon, Input } from 'antd';
import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dotsImage from '../../../assets/imgs/draggable-dots.png';
import { isEmpty } from '../../../utils/activity-logs';
import { AiFillFileText, AiFillPrinter, AiFillPicture, AiFillEdit, AiFillLayout, AiOutlineEnvironment, AiOutlineLine } from 'react-icons/ai';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

class DragDropComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: props.items
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd(result) {
		const { isWritePermitted, getItemsOrder } = this.props;
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		if(isWritePermitted === true) {
			const items = reorder(
				this.state.items,
				result.source.index,
				result.destination.index
			);

			items.forEach((item, index) => { return item.id = (index + 1).toString(); });
			this.setState({
				items
			});
		
			getItemsOrder(items);
		}
	}

	displayItemsForm(item) {
		const { getFieldDecorator, validateTrigger } = this.props;
		return (
			<>
				<div className="drag-items dropdown-drag">
					<img className="drag-dots" src={dotsImage} alt="drag dots" />
					<Form.Item label={`Items ${item.id}`}>
						{getFieldDecorator(`items${item.id}`, {
							validateTrigger: validateTrigger ? 'onChange' : '',
							rules: [
								{ required: true, message: `Please enter item ${item.id}` },
							],
							initialValue: !isEmpty(item.label) ? item.label : ''
						})(
							<Input onChange={(e) => item.label = e.target.value} />
						)}
					</Form.Item>
				</div>
			</>
		);
	}

	displayImagesForm(item) {
		const { getFieldDecorator, validateTrigger } = this.props;
		return (
			<div className="drag-items dropdown-drag">
				<img style={{ margin: '0px 0 0' }} className="drag-dots" src={dotsImage} alt="drag dots" />
				<Form.Item>
					{getFieldDecorator(`items${item.id}`, {
						validateTrigger: validateTrigger ? 'onChange' : '',
						rules: [
							{ required: true, message: `Please enter item ${item.id}` },
						],
						initialValue: !isEmpty(item.label) ? item.label : ''
					})(
						<Input placeholder="Add option..." onChange={(e) => item.label = e.target.value} />
					)}
				</Form.Item>
			</div>
		);
	}

	imagesIconFormContent(type) {
		return (
			type === 'dropdown' ? <Icon type="check-square" className="field-icons" /> :
				type === 'task' ? <Icon type="check-circle" className="field-icons" /> :
					type === 'number' ? <Icon type="number" className="field-icons" /> :
						type === 'date' ? <Icon type="calendar" className="field-icons" /> :
							type === 'openEnded' ? <Icon type="font-size" className="field-icons" /> :
								type === 'rating' ? <Icon type="star" className="field-icons" /> :
									type === 'boolean' ? <Icon type="check" className="field-icons" /> :
										type === 'imageUpload' ? <AiFillPicture className="field-icons" /> :
											type === 'scanner' ? <AiFillPrinter className="field-icons" /> :
												type === 'signature' ? <AiFillEdit className="field-icons" /> :
													type === 'imageSelection' ? <AiFillLayout className="field-icons" /> :
														type === 'fileUpload' ? <Icon type="upload" className="field-icons" /> :
															type === 'location' ? <AiOutlineEnvironment className="field-icons" /> :
																type === 'numberSlider' ? <AiOutlineLine className="field-icons" /> :
																	type === 'audioRecording' ? <Icon type="audio" className="field-icons" /> : null
		);
	}

	displayFormContentList(value, index) {
		const { updateFormField, deleteFormField, copyFormField, isDeletePermitted } = this.props;
		return (
			<div className="drag-items" onClick={() => updateFormField(value)}>
				<p className="field-index">{value.id}</p>
				<div className="field-lists" key={value.id}>
					<img className="drag-dots" src={dotsImage} alt="drag dots" />
					{this.imagesIconFormContent(value.type)}
					{value.type === 'description' ?
						<p className="field-content">
							<span className="title-label" dangerouslySetInnerHTML={{ __html: value.label }} />
						</p>
						:
						<p className="field-content">
							<span className="title-label">{value.label}</span>
							<span className="subtexts">{value.type}</span>
						</p>
					}
					<div className="copy-delete-icons">
						<button className="copy-icon">
							{value.type !== 'description' &&
								<Icon type="copy" onClick={(e) => { e.stopPropagation(); copyFormField(value); }} />
							}
						</button>
						{isDeletePermitted === true &&
							<button className="delete-icon" onClick={(e) => { e.stopPropagation(); deleteFormField(index); }}>
								<Icon className="error-text" type="delete" />
							</button>
						}
					</div>
				</div>
			</div>
		);
	}

	displayFormList = (value) => {
		const { updateObjectDetails, deleteObjectDetails, isWritePermitted, isDeletePermitted } = this.props;
		return (
			<div key={value.orderId} className="object-details">
				<img className="drag-dots" src={dotsImage} alt="drag dots" />
				<AiFillFileText className="IoIosAddCircleOutline-custom" />
				<span>{value.formTitle}</span>
				{isWritePermitted &&
					<Icon type="edit" className="edit-delete-icon" onClick={() => updateObjectDetails(value)} />
				}
				{isDeletePermitted &&
					<Icon type="delete" className="edit-delete-icon error-text" onClick={() => deleteObjectDetails(value)} />
				}
			</div>
		);
	}

	render() {
		const { items } = this.state;
		const { formType } = this.props;
		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{items.map((item, index) => (
								<Draggable key={item.id} draggableId={item.id} index={index}>
									{(provided) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
										>
											{formType === 'list' && this.displayFormContentList(item, index)}
											{formType === 'image' && this.displayImagesForm(item)}
											{formType === 'field' && this.displayItemsForm(item)}
											{formType === 'formList' && this.displayFormList(item)}
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		);
	}
}
export default DragDropComponent;
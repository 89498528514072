import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Dropdown, Menu, Select, DatePicker, Checkbox, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import firebase from '../../../services/firebase';
import moment from 'moment';
import { updateEmail } from '../../../services/auth';
import { checkWritePermissions } from '../../../utils/native';
import { isEmpty } from '../../../utils/activity-logs';

class AddLOSEmployee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'users'),
			errorMessage: '',
			validateTrigger: false,
			user: {},
			menuStatus: (
				<Menu>
					<Menu.Item key="admin" onClick={(value) => this.onValueChange('userType', value.key)}>Admin</Menu.Item>
					<Menu.Item key="LOS" onClick={(value) => this.onValueChange('userType', value.key)}>LOS Employee</Menu.Item>
				</Menu>
			),
			losHousing: false,
			mentorProgram: false,
			onboarding: false,
			id: props.match.params.id,
			oldEmail: '',
			groupLists: [],
			segments: [],
			isAddAdmin: props.match.params.users,
			personalTruck: false,
			companyTruck: false,
			shopList: [
				{ key: 'admin', value: 'Admin-Receptionist' }, { key: 'asst_manager', value: 'Asst. Manager' }, { key: 'contractor', value: 'Contractor' },
				{ key: 'crane', value: 'Crane Operator' }, { key: 'dispatch', value: 'Dispatch' }, { key: 'field', value: 'Field' },
				{ key: 'forklift', value: 'Forklift (DOT)' }, { key: 'contract', value: 'HSE - Advisor (Contract)' }, { key: 'trainer', value: 'HSE - Advisor (Field Trainer)' },
				{ key: 'coordinator', value: 'HSE - Coordinator' }, { key: 'director', value: 'HSE - Director' }, { key: 'ofc_manager', value: 'OFC Manager' },
				{ key: 'office', value: 'Office' }, { key: 'shop', value: 'Shop' },
			]
		};
	}

	componentDidMount() {
		const { id } = this.state;
		if (id !== undefined) {
			this.getSelectedUser();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'users') });
		}
	}

	getSelectedUser() {
		const { id, isAddAdmin } = this.state;
		
		this.setState({ Loader: true });
		const { usersList } = this.props.auth;
		const temp = usersList.find((item) => item.$key === id);
		this.setState({
			user: temp,
			userType: isAddAdmin === 'los' ? 'LOS' : 'Admin',
			oldEmail: temp.email,
			Loader: false
		});
		// this.getSections();
	}

	getSections() {
		const { user } = this.state;
		
		const db = firebase.firestore();
		const segmentsRef = db.collection('segments').orderBy('createdAt', 'desc');
		segmentsRef.get().then((querySnapshot) => {
			const temp = [], temp_segments = [];
			user.groups = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp_segments.push({ $key: doc.id});
						data.groups.forEach((group) => {
							group.segment_id = doc.id;
							temp.push(group);
						});
					}
				}
			});
			temp.forEach((group, index) => {
				if (group.joinedUsers.length > 0 && user.uid) {
					const joined = group.joinedUsers;
					joined.forEach((join) => {
						if(join === user.uid) {
							user.groups.push(index);
						}
					});
				}
			});
			this.setState({ Loader: false });
			this.setState({ groupLists: temp });
			this.setState({ segments: temp_segments });
		});
	}

	onDateChange = (type, value) => {
		const { user } = this.state;
		user[type] = moment(value).format('YYYY-MM-DD');
		this.setState({ ...user });
	}

	onInputChange(key, value) {
		const { user, groupLists } = this.state;
		if (key === 'groups') {
			value.forEach((v) => {
				if (groupLists[v].joinedUsers.includes(user.uid) === false) {
					groupLists[v].joinedUsers.push(user.uid);
				}
			});
		} else {
			user[key] = value;
			this.setState({ ...user });
		}
	}

	onDeselect(_, value) {
		const { user, groupLists } = this.state;
		const index = groupLists[value].joinedUsers.indexOf(user.uid);
		groupLists[value].joinedUsers.splice(index, 1);
	}

	onValueChange(key, value) {
		const oldUser = this.state.user;
		oldUser[key] = value;
		this.setState({ user: oldUser });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { form } = this.props;
		const { user, oldEmail, segments, groupLists, isAddAdmin } = this.state;
		const { currentUser } = this.props.auth;
		
		form.validateFields(async (err, values) => {
			this.setState({ validateTrigger: true });
			values.employmentStartDate = values.employmentStartDate !== undefined ? moment(values.employmentStartDate).format('YYYY-MM-DD') : undefined;
			values.lastEvaluationDate = values.lastEvaluationDate !== undefined ? moment(values.lastEvaluationDate).format('YYYY-MM-DD') : undefined;
			values.termDate = values.termDate !== undefined ? moment(values.termDate).format('YYYY-MM-DD') : undefined;
			values.createdAt = moment().valueOf();
			await segments.forEach(async (segment, index) => {
				await groupLists.forEach(async (group) => {
					if(segment.$key === group.segment_id) {
						if (segments[index].groups !== undefined && segments[index].groups.length > 0) {
							segments[index].groups.push(group);
						} else {
							segments[index].groups = [];
							segments[index].groups.push(group);
						}
					}
				});
			});

			if (user.userType === undefined) {
				if (isAddAdmin === 'los') {
					user.userType = 'LOS';
				} else {
					user.userType = 'admin';
				}
			}

			if (!err) {
				const database = firebase.firestore();
				this.setState({
					errorMessage: ''
				});
				if (this.state.id === undefined) {
					firebase.auth().createUserWithEmailAndPassword(user.email, user.password).then(async (response) => {
						user.registerVerifyCode = Math.floor(100000 + Math.random() * 900000);
						user.registrationVerified = 'no';
						user.createdAt = moment().valueOf();
						user.uid = response.user.uid;
						user.verified = true;
						delete user.password;

						user.modulePermissions = {
							modules: [
								{ type: 'rigs', read: false, write: false, delete: false },
								{ type: 'users', read: false, write: false, delete: false },
								{ type: 'pusher', read: false, write: false, delete: false },
								{ type: 'reports', read: false, write: false, delete: false },
								{ type: 'losSafety', read: false, write: false, delete: false },
								{ type: 'smartGroups', read: false, write: false, delete: false },
								{ type: 'rigPics', read: false, write: false, delete: false },
								{ type: 'assets', read: false, write: false, delete: false },
								{ type: 'individualLoads', read: false, write: false, delete: false },
							],
							createdAt: moment().valueOf(),
							createdBy: currentUser.uid
						};

						await database.collection('users').add(user).then(() => {
							this.props.history.push('/users');
						});
					}).catch((error) => {
						alert(error.message);
					});
				} else if (oldEmail === user.email) {
					this.updateUserDetails();
				} else {
					this.setState({ loader: true });
					updateEmail(user.uid, user.email).then(async () => {
						this.setState({ loader: false });
						this.updateUserDetails();
					}).catch((error) => {
						this.setState({ loader: false });
						if (error.response && error.response.data) {
							alert(error.response.data.message);
						} else {
							alert('Something went wrong!');
						}
					});
				}
			}
		});
	}

	async updateUserDetails() {
		const { user, segments, id } = this.state;
		const database = firebase.firestore();
		await segments.forEach(async(segment) => {
			await database.doc(`segments/${segment.$key}`).update(segment);
		});
		await database.doc(`users/${id}`).update(user)
			.then(() => this.props.history.push('/users'))
			.catch(() => { });
	}

	render() {
		const { validateTrigger, errorMessage, menuStatus, losHousing, mentorProgram, onboarding, user, id, isAddAdmin, personalTruck, companyTruck, shopList } = this.state;
		const { form: { getFieldDecorator } } = this.props;
		const { Option } = Select;
		return (
			<div className="edit-rig-area create-new-user-part">
				<h4 className="card-title no-mb">{id === undefined ? 'Create New User' : 'Edit User'}</h4>
				<div className="card-body">
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
						<Row>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="First Name">
									{getFieldDecorator('firstName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter your first name' }
										],
										initialValue: user.firstName,
									})(
										<Input
											onChange={(e) => this.onInputChange('firstName', e.target.value)}
										/>,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Last Name">
									{getFieldDecorator('lastName', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter your last name' }
										],
										initialValue: user.lastName
									})(
										<Input
											onChange={(e) => this.onInputChange('lastName', e.target.value)}
										/>,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Email">
									{getFieldDecorator('email', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ type: 'email', message: 'Invalid email address' },
											{ required: true, message: 'Please enter your email' }
										],
										initialValue: user.email
									})(
										<Input
											autoComplete="email"
											onChange={(e) => this.onInputChange('email', e.target.value)}
										/>,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Phone Number">
									{getFieldDecorator('phoneNumber', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter your phone number' },
											{ pattern: '^([0-9]|[0-9]-)*$', message: 'Please enter valid phone number' }
										],
										initialValue: user.phoneNumber
									})(
										<Input
											onChange={(e) => this.onInputChange('phoneNumber', e.target.value)}
										/>,
									)}
								</Form.Item>
							</Col>
							{id === undefined &&
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Password">
										{getFieldDecorator('password', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											rules: [
												{ required: true, message: 'Please enter your password' },
											],
											initialValue: user.password
										})(
											<Input
												type="password"
												autoComplete="new-password"
												onChange={(e) => this.onInputChange('password', e.target.value)}
											/>,
										)}
									</Form.Item>
								</Col>
							}
							<Col md={24} xl={12} span={24}>
								<Form.Item label="User Type">
									{getFieldDecorator('userType', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.userType
									})(
										<Dropdown disabled={id === undefined} overlayClassName="custom-dropdown-option" className="custom-dropdown-part" overlay={menuStatus} placement="bottomCenter" arrow>
											<Button size="large">
												{id === undefined ?
													isAddAdmin === 'los' ? 'LOS Employee' : 'Admin' :
													user.userType === 'admin' ? 'Admin' : 'LOS Employee'
												}
											</Button>
										</Dropdown>
									)}
								</Form.Item>
							</Col>
							{(id === undefined ? isAddAdmin === 'los' : user.userType === 'LOS') &&
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Country Code:">
										{getFieldDecorator('countryCode', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											initialValue: user.countryCode,
										})(
											<Input
												onChange={(e) => this.onInputChange('countryCode', e.target.value)} />,
										)}
									</Form.Item>
								</Col>
							}
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Employee ID">
									{getFieldDecorator('employeeID', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.employeeID,
									})(
										<Input
											onChange={(e) => this.onInputChange('employeeID', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Shop/Office/Field">
									{getFieldDecorator('selectedShop', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter Shop' },
										],
										initialValue: user.selectedShop
									})(
										<Select
											size="large"
											mode="multiple"
											dropdownClassName="custom-select-option" 
											className="custom-select-multiple"
											onChange={(value) => this.onInputChange('selectedShop', value)}
										>
											{shopList.map((item, index) => {
												return (
													<Option key={index} value={item.key}>{item.value}</Option>
												);
											})}
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Location">
									{getFieldDecorator('selectedLocation', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter location' },
										],
										initialValue: user.selectedLocation
									})(
										<Select 
											dropdownClassName="custom-select-option" 
											className="custom-select-multiple"
											size="large"
											mode="multiple"
											onChange={(value) => this.onInputChange('selectedLocation', value)}
										>
											<Option value="Carlsbad">Carlsbad</Option>
											<Option value="Midland">Midland</Option>
											<Option value="Midland Corporate">Midland Corporate</Option>
											<Option value="NTX">NTX</Option>
											<Option value="Production">Production</Option>
											<Option value="Corporate (Main)">Corporate (Main)</Option>
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Employment Start Date">
									{getFieldDecorator('employmentStartDate', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: !isEmpty(user.employmentStartDate) ? moment(user.employmentStartDate) : undefined
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											onChange={(value) => this.onDateChange('employmentStartDate', value)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Last Evaluation Date">
									{getFieldDecorator('lastEvaluationDate', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: !isEmpty(user.lastEvaluationDate) ? moment(user.lastEvaluationDate) : undefined
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											onChange={(value) => this.onDateChange('lastEvaluationDate', value)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Group">
									{getFieldDecorator('group', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										rules: [
											{ required: true, message: 'Please enter group' },
										],
										initialValue: user.group
									})(
										<Select 
											dropdownClassName="custom-select-option" 
											className="custom-select-multiple"
											size="large"
											mode="multiple"
											onChange={(value) => this.onInputChange('group', value)}
										>
											<Option value="Driver">Driver</Option>
											<Option value="Swamper">Swamper</Option>
											<Option value="Rigger">Rigger</Option>
											<Option value="Manager">Manager</Option>
											<Option value="Truck Pusher">Truck Pusher</Option>
											<Option value="Safety Swamper">Safety Swamper</Option>
											<Option value="Crane Operator">Crane Operator</Option>
											<Option value="Other">Other</Option>
										</Select>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Direct Manager">
									{getFieldDecorator('directManager', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.directManager,
									})(
										<Input
											onChange={(e) => this.onInputChange('directManager', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Title_">
									{getFieldDecorator('title_', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.title_,
									})(
										<Input
											onChange={(e) => this.onInputChange('title_', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24} id="DropdownFixPostion">
								<Form.Item label="Term Date">
									{getFieldDecorator('termDate', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: !isEmpty(user.termDate) ? moment(user.termDate) : undefined
									})(
										<DatePicker
											getCalendarContainer={() => document.getElementById('DropdownFixPostion')}
											onChange={(value) => this.onDateChange('termDate', value)}
											style={{ width: '100%' }}
										/>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Original Phone Number">
									{getFieldDecorator('originalPhoneNo', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.originalPhoneNo,
									})(
										<Input
											onChange={(e) => this.onInputChange('originalPhoneNo', e.target.value)} />,
									)}
								</Form.Item>
							</Col>
							{/* {id !== undefined &&
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Groups">
										{getFieldDecorator('groups', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											initialValue: user.groups,
										})(
											<Select
												dropdownClassName="custom-select-option"
												className="custom-select-multiple"
												size="large"
												mode="multiple"
												onChange={(value) => this.onInputChange('groups', value)}
												onDeselect={(value) => this.onDeselect('groups', value)}
											>
												{groupLists && groupLists.map((group, index) => {
													return (
														<Option key={index} value={index}>{group.name}</Option>
													);
												})}
											</Select>
										)}
									</Form.Item>
								</Col>
							} */}
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Personal Truck:" className="custom-checkbox">
									{getFieldDecorator('personalTruck', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.personalTruck,
									})(
										<Checkbox className='checkPos' checked={user.personalTruck} onChange={() => this.onInputChange('personalTruck', !personalTruck)}>Yes</Checkbox>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="Company Truck:" className="custom-checkbox">
									{getFieldDecorator('companyTruck', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.companyTruck,
									})(
										<Checkbox className='checkPos' checked={user.companyTruck} onChange={() => this.onInputChange('companyTruck', !companyTruck)}>Yes</Checkbox>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="LOS HOUSING:" className="custom-checkbox">
									{getFieldDecorator('losHousing', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.losHousing,
									})(
										<Checkbox className='checkPos' checked={user.losHousing} onChange={() => this.onInputChange('losHousing', !losHousing)}>Yes</Checkbox>
									)}
								</Form.Item>
							</Col>
							<Col md={24} xl={12} span={24}>
								<Form.Item label="MENTOR PROGRAM:" className="custom-checkbox">
									{getFieldDecorator('mentorProgram', {
										validateTrigger: validateTrigger ? 'onChange' : '',
										initialValue: user.mentorProgram,
									})(
										<Checkbox className='checkPos' checked={user.mentorProgram} onChange={() => this.onInputChange('mentorProgram', !mentorProgram)}>Yes</Checkbox>
									)}
								</Form.Item>
							</Col>
							{(id === undefined ? isAddAdmin === 'los' : user.userType === 'LOS') &&
								<Col md={24} xl={12} span={24}>
									<Form.Item label="Onboarding:" className="custom-checkbox">
										{getFieldDecorator('onboarding', {
											validateTrigger: validateTrigger ? 'onChange' : '',
											initialValue: user.onboarding,
										})(
											<Checkbox className='checkPos' checked={user.onboarding} onChange={() => this.onInputChange('onboarding', !onboarding)}>Yes</Checkbox>
										)}
									</Form.Item>
								</Col>
							}
						</Row>
						<span>{errorMessage}</span>
						<Form.Item className="button-section">
							{id === undefined ?
								<Button type="primary" htmlType="submit" size="large" className="submitBtn">
									Create User
								</Button>
								:
								<Button type="primary" htmlType="submit" size="large" className="submitBtn">
									Update
								</Button>
							}
						</Form.Item>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(AddLOSEmployee)));